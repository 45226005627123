import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import isRole from './util/isRole';
import {useSelector} from 'react-redux'
import openInNewTab from "./util/openInNewTab";
import config from "react-global-configuration";
import { mainApi } from '../HttpClient';

const Navigation = () => {
    const userLogin = useSelector((state) => state.userLogin.value);
    const documentState = useSelector((state) => state.document);

    const [inboxWaitingCount, setInboxWaitingCount] = useState('');
    const [secretInboxWaitingCount, setSecretInboxWaitingCount] = useState('');

    const checkMasOrganize = (organizeSelected) => {
        let result = false;
        if (userLogin.org.orgId === organizeSelected) {
            result = true;
        }
        return result;
    };

    const roleSpecialCheck = () => {
        let result = 'none';

        if (isRole(['super-admin'])) {
            result = '';
        }
        if (isRole(['admin'])) {
            result = '';
        }
        if (isRole(['admin-dep-out'])) {
            result = '';
        }
        if (isRole(['out'])) {
            result = '';
        }

        return result;
    };

    // const roleSpecialCheckMasExpert = () => {
    //     let result = 'none';
    //     // เฉพาะ admin และอยู่ในสำนักผู้ทรงคุณวุฒิ
    //     if (isRole(['admin']) && checkMasOrganize('1024')) {
    //         result = '';
    //     }
    //
    //     return result;
    // };

    const roleSpecialCheckMasManager = () => {
        let result = 'none';
        const curUser = userLogin.employee_id;

        if (
            isRole([
                'super-admin',
                'admin',
                'admin-dep',
                'admin-dep-out',
                'admin-sup-dep',
                'user',
            ])
        ) {
            //ผู้บริหารทั้ง 8
            if (
                curUser !== 'u1001' &&
                curUser !== 'u1678' &&
                curUser !== 'u2125' &&
                curUser !== 'u2171' &&
                curUser !== 'u2250' &&
                curUser !== 'u2251' &&
                curUser !== 'u2435' &&
                curUser !== 'u1464'
                // &&
                // curUser !== 'u2441' /* ศูนย์วัคซีน */
            ) {
                result = '';
            }
        }

        return result;
    };
    const roleSpecialForRegisterExpert = () => {
        let result = 'none';
        if (isRole(['super-admin'])) {
            result = '';
        } else if (isRole(['admin']) && checkMasOrganize('1024')) {
            result = '';
        } else {
            result = 'none';
        }
        return result;
    };

    const {id: documentById} = documentState.documentBy;

    useEffect(() => {
      
        mainApi.post(`/api/v3/documents?pageName=inbox-waiting&receiverId=${documentById}&documentById=${documentById}`,{"draw":1,"columns":[{"data":"documentDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":1,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"registerNo","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"documentDate","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},{"data":4,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"sender","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"receiver","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"owner","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"subject","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"command","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"sentDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"progressStatus","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":12,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"desc"}],"start":0,"length":10,"search":{"value":"","regex":false}}).then((res) => {
            setInboxWaitingCount(res.data.recordsFiltered);
            
        });

        mainApi.post(`/api/v3/documents?pageName=secret-doc-inbox-waiting&receiverId=${documentById}&documentById=${documentById}`,{"draw":1,"columns":[{"data":"documentDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":1,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"registerNo","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"documentDate","name":"","searchable":true,"orderable":false,"search":{"value":"","regex":false}},{"data":4,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"sender","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"receiver","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"owner","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"subject","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"command","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"sentDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"progressStatus","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":12,"name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"desc"}],"start":0,"length":10,"search":{"value":"","regex":false}}).then((res) => {
            setSecretInboxWaitingCount(res.data.recordsFiltered);
            
        });

    }, [documentById]);
    

    useEffect(() => {
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
    }, []);

    return (
        <aside className="main-sidebar elevation-4 sidebar-color">
            <Link to="./dashboard" className="brand-link sidebar-link-color">
                <span className="brand-text font-weight-light">&nbsp;</span>
            </Link>
            <Scrollbars style={{height: '100vh'}}>
                <div className="sidebar sidebar-color">
                    <nav className="mt-5 mb-5">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            // ปิดไม่ให้ย่อหดเมนูได้
                            // data-widget="treeview"
                            role="menu"
                            data-accordion="true"
                        >
                            <li className="nav-item">
                                <Link to="./dashbordV2" className="nav-link sidebar-link-color nvClick">
                                    <i className="nav-icon fas fa-home sidebar-fa-size-header mr-2"></i>
                                    <p>หน้าหลัก</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview menu-open"
                                style={{
                                    display:
                                        !isRole([
                                            'super-admin',
                                            'admin',
                                            'admin-dep',
                                            'admin-dep-out',
                                            'admin-sup-dep',
                                            'out',
                                            'user',
                                        ]) && 'none',
                                }}>
                                <Link to="#" className="nav-link sidebar-link-color nvClick">
                                    <i className="nav-icon fas fa-inbox sidebar-fa-size-header mr-2"></i>
                                    <p>ทะเบียนหนังสือรับ<i className="fas fa-angle-left right"></i></p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="./inbox-internal" className="nav-link sidebar-link-color">
                                            <p>หนังสือรับ (ภายใน)</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" style={{display: !isRole(['super-admin', 'admin-dep-out', 'out']) && 'none'}}>
                                        <Link to="./inbox-external" className="nav-link sidebar-link-color">
                                            <p>หนังสือรับ (ภายนอก)</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="./inbox-waiting" className="nav-link sidebar-link-color">
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <p>หนังสือรับ (รอลงรับ)</p>
                                               {inboxWaitingCount !== 0 && <span class="badge badge-danger">{inboxWaitingCount}</span>}
                                            </div>
                                        </Link>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link to="./inbox" className="nav-link sidebar-link-color">*/}
                                    {/*        <p>ทะเบียนหนังสือรับทั้งหมด</p>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <Link to="./inbox-int" className="nav-link sidebar-link-color">
                                            <p>ทะเบียนหนังสือรับภายใน</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" style={{display: !isRole(['super-admin', 'admin-dep-out', 'out']) && 'none'}}>
                                        <Link to="./inbox-ext" className="nav-link sidebar-link-color">
                                            <p>ทะเบียนหนังสือรับภายนอก</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {documentState.documentBy && !['manager', 'expert', 'employee'].includes(documentState.documentBy.type) && (
                                <li className="nav-item has-treeview menu-open" style={{display: roleSpecialCheckMasManager()}}>
                                    <Link to="#" className="nav-link sidebar-link-color nvClick">
                                        <i className="nav-icon fas fa-paper-plane sidebar-fa-size-header mr-2 nvClick"></i>
                                        <p>ทะเบียนออกเลขส่ง<i className="fas fa-angle-left right"></i></p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./outbox-draft" className="nav-link sidebar-link-color">
                                                <p>ร่าง (จองเลข)</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./outbox-internal" className="nav-link sidebar-link-color">
                                                <p>ออกเลขภายใน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item" style={{display: roleSpecialCheck()}}>
                                            <Link to="./outbox-external" className="nav-link sidebar-link-color">
                                                <p>ออกเลขภายนอก</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./outbox" className="nav-link sidebar-link-color">
                                                <p>ทะเบียนออกเลขทั้งหมด</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {documentState.documentBy && !['manager', 'expert', 'employee'].includes(documentState.documentBy.type) && (
                                <li className="nav-item has-treeview menu-open" style={{display: roleSpecialCheckMasManager()}}>
                                    <Link to="#" className="nav-link sidebar-link-color nvClick">
                                        <i className="nav-icon fas fa-copy sidebar-fa-size-header mr-2 nvClick"></i>
                                        <p>ทะเบียนออกเลขเวียน<i className="fas fa-angle-left right"></i></p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./circular-draft" className="nav-link sidebar-link-color">
                                                <p>ร่างเวียน (จองเลข)</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./circular-internal" className="nav-link sidebar-link-color">
                                                <p>ออกเลขเวียนภายใน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item" style={{display: roleSpecialCheck()}}>
                                            <Link to="./circular-external" className="nav-link sidebar-link-color">
                                                <p>ออกเลขเวียนภายนอก</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item"
                                            style={{
                                                display:
                                                    !isRole([
                                                        'super-admin',
                                                        'admin',
                                                        'admin-dep',
                                                        'admin-dep-out',
                                                        'admin-sup-dep',
                                                        'user',
                                                    ]) && 'none',
                                            }}>
                                            <Link to="./circular" className="nav-link sidebar-link-color">
                                                <p>ทะเบียนออกเลขเวียนทั้งหมด</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            <li className="nav-item has-treeview menu-open" style={{display: !isRole(['secret-doc']) && 'none'}}>
                                <Link to="#" className="nav-link sidebar-link-color nvClick">
                                    <span className="fa-stack nav-icon sidebar-fa-size-header mr-2" style={{fontSize: '1rem'}}>
                                        <i className="fas fa-square fa-stack-2x"></i>
                                        <i className="fas fa-inbox fa-stack-1x" style={{color: '#2b383e'}}></i>
                                    </span>
                                    <p>ทะเบียนหนังสือรับ (ลับ)<i className="fas fa-angle-left right"></i></p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="./secret-doc-inbox-internal" className="nav-link sidebar-link-color">
                                            <p>หนังสือรับ (ลับ)</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="./secret-doc-inbox-waiting" className="nav-link sidebar-link-color">
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <p>หนังสือรับ (รอลงรับ) (ลับ)</p>
                                               {secretInboxWaitingCount !== 0 && <span class="badge badge-danger">{secretInboxWaitingCount}</span>}
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="./secret-doc-inbox" className="nav-link sidebar-link-color">
                                            <p>ทะเบียนหนังสือรับ (ลับ)</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {documentState.documentBy && !['manager', 'expert', 'employee'].includes(documentState.documentBy.type) && (
                                <li className="nav-item has-treeview menu-open" style={{display: !isRole(['secret-doc']) && 'none'}}>
                                    <Link to="#" className="nav-link sidebar-link-color nvClick">
                                        <span className="fa-stack nav-icon sidebar-fa-size-header mr-2 nvClick" style={{fontSize: '1rem'}}>
                                            <i className="fas fa-square fa-stack-2x"></i>
                                            <i className="fas fa-paper-plane fa-stack-1x" style={{color: '#2b383e'}}></i>
                                        </span>
                                        <p>ทะเบียนออกเลขส่ง (ลับ)<i className="fas fa-angle-left right"></i></p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="./secret-doc-outbox-internal" className="nav-link sidebar-link-color">
                                                <p>ออกเลขภายใน (ลับ)</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="./secret-doc-outbox-external" className="nav-link sidebar-link-color">
                                                <p>ออกเลขภายนอก (ลับ)</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="./secret-doc-outbox" className="nav-link sidebar-link-color">
                                                <p>ทะเบียนออกเลขทั้งหมด (ลับ)</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {/*<li*/}
                            {/*    className="nav-item has-treeview"*/}
                            {/*    style={{*/}
                            {/*        display:*/}
                            {/*            !isRole([*/}
                            {/*                'super-admin',*/}
                            {/*                'admin',*/}
                            {/*                'admin-dep',*/}
                            {/*                'admin-dep-out',*/}
                            {/*                'admin-sup-dep',*/}
                            {/*                'user',*/}
                            {/*            ]) && 'none',*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Link to="./all-form" className="nav-link sidebar-link-color">*/}
                            {/*        <i className="nav-icon far fa-file sidebar-fa-size-header mr-2 nvClick"></i>*/}
                            {/*        <p>แบบฟอร์ม</p>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className="nav-item has-treeview">
                                <Link to="./report" className="nav-link sidebar-link-color">
                                    <i className="nav-icon far fa-file sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>รายงาน</p>
                                </Link>
                            </li>
                            <li
                                className="nav-item has-treeview"
                                style={{
                                    display:
                                        !isRole([
                                            'super-admin',
                                            'admin',
                                            'admin-dep',
                                            'admin-dep-out',
                                            'admin-sup-dep',
                                        ]) && 'none',
                                }}
                            >
                                <Link to="./archive-document" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-archive sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ประวัติหนังสือ</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview" style={{display: !isRole(['system']) && 'none'}}>
                                <Link to="./mas-manager" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-user sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ข้อมูลผู้บริหาร</p>
                                </Link>
                            </li>
                            {/*<li className="nav-item has-treeview" style={{display: roleSpecialCheckMasExpert()}}>*/}
                            <li className="nav-item has-treeview"
                                style={{display: !isRole(['system']) && 'none'}}>
                                <Link to="./mas-expert" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-user sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ข้อมูลผู้ทรงคุณวุฒิ</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview" style={{display: !isRole(['system']) && 'none'}}>
                                <Link to="./mas-organization" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-sitemap sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ข้อมูลโครงสร้างหน่วยงาน</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview" style={{display: !isRole(['system']) && 'none'}}>
                                <Link to="./special-organization" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-briefcase sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ผูกหน่วยงานพิเศษกับผู้ใช้งาน</p>
                                </Link>
                            </li>
                            <li
                                className="nav-item has-treeview"
                                style={{
                                    display:
                                        !isRole([
                                            'super-admin',
                                            'admin',
                                            'admin-dep',
                                            'admin-dep-out',
                                            'admin-sup-dep',
                                            'user',
                                        ]) && 'none',
                                }}
                            >
                                <Link to="./mas-document-register-by-user-login" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fas fa-save sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ทะเบียนเลขที่หนังสือ</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview d-none" style={{display: !isRole(['super-admin']) && 'none'}}>
                                <Link to="./transfer-books" className="nav-link sidebar-link-color">
                                    <i className="nav-icon fa fa-book sidebar-fa-size-header mr-2  mr-2 nvClick"></i>
                                    <p>โอนย้ายหนังสือ</p>
                                </Link>
                            </li>
                            <li className="nav-item has-treeview menu-open d-none">
                                <Link to="#" className="nav-link sidebar-link-color nvClick">
                                    <i className="nav-icon fas fa-copy sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>จัดการ<i className="fas fa-angle-left right"></i></p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="./register-org-edit" className="nav-link sidebar-link-color"
                                              style={{
                                                  display:
                                                      !isRole([
                                                          'super-admin',
                                                          'admin',
                                                      ]) && 'none',
                                              }}>
                                            <p>กำหนดเลขสธ.หน่วยงาน</p>
                                        </Link>
                                        <Link to="./register-manager-edit" className="nav-link sidebar-link-color"
                                              style={{
                                                  display:
                                                      !isRole([
                                                          'super-admin',
                                                      ]) && 'none',
                                              }}>
                                            <p>กำหนดเลขสธ.ผู้บริหาร</p>
                                        </Link>
                                        <Link to="./register-expert-edit" className="nav-link sidebar-link-color"
                                              style={{display: roleSpecialForRegisterExpert() && 'none'}}>
                                            <p>กำหนดเลขสธ.ผู้ทรงคุณวุฒิ</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {/* {documentState.documentBy && !['manager', 'expert', 'employee' ].includes(documentState.documentBy.type) && ( */}
                                {/* <li className="nav-item has-treeview menu-open" style={{display: !isRole(['user']) && 'none'}}> */}
                                <li className="nav-item has-treeview menu-open">
                                    <Link to="#" className="nav-link sidebar-link-color nvClick">
                                        <i className="nav-icon fas fa-paste sidebar-fa-size-header mr-2 nvClick"></i>
                                        <p>ทะเบียนแม่แบบ<i className="fas fa-angle-left right"></i></p>
                                    </Link>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="./form-auto-menu-list" className="nav-link sidebar-link-color">
                                                <p>แม่แบบ</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="./form-auto-menu-copy-list" className="nav-link sidebar-link-color">
                                                <p>แม่แบบ (คัดลอกข้อมูล)</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="./form-auto-box" className="nav-link sidebar-link-color">
                                                <p>รายการแม่แบบ</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            {/* )} */}
                            <li className="nav-item has-treeview menu-open" style={{display: !isRole(['user']) && 'none'}}>
                                <Link to="#" className="nav-link sidebar-link-color nvClick">
                                    <i className="nav-icon fas fa-cog sidebar-fa-size-header mr-2 nvClick"></i>
                                    <p>ตั้งค่าข้อมูล<i className="fas fa-angle-left right"></i></p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="./rubber-stamp-bind-data"*/}
                                    {/*        className="nav-link sidebar-link-color"*/}
                                    {/*        style={{*/}
                                    {/*            display:*/}
                                    {/*                !isRole([*/}
                                    {/*                    'admin',*/}
                                    {/*                ]) && 'none',*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <p>ผูกข้อมูลผู้ใช้งาน</p>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <Link to="./rubber-stamp-data" className="nav-link sidebar-link-color">
                                            <p>ข้อมูลตรายางประทับ</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="./rubber-stamp-receive" className="nav-link sidebar-link-color">
                                            <p>ข้อมูลตรายาง (หนังสือรับ)</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="" className="nav-link sidebar-link-color"
                                              onClick={() => {
                                                  openInNewTab(config.get('ssoSarabunUrl'));
                                              }}
                                              style={{display: !isRole(['system']) && 'none'}}>
                                            <p>จัดการสิทธิ์ผู้ใช้งาน</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/*** บังคับให้เว็บโหลด font เพื่อนำไปใช้ตอนสร้าง canvas ด้วย font เหล่านี้ ***/}
                <div>
                    <span className="font-thsarabun">{/*Load font ไทยสาระบรรณ*/}</span>
                    <span className="font-thsarabun-bold">{/*Load font ไทยสาระบรรณ Bold*/}</span>
                    <span className="font-thsarabun-bolditalic">{/*Load font ไทยสาระบรรณ Bold Italics*/}</span>
                    <span className="font-thsarabun-italic">{/*Load font ไทยสาระบรรณ Italics*/}</span>
                    <span className="font-thsarabunit9">{/*Load font ไทยสาระบรรณ IT 9*/}</span>
                    <span className="font-thsarabunit9-bold">{/*Load font ไทยสาระบรรณ IT 9 Bold*/}</span>
                    <span className="font-thsarabunit9-bolditalic">{/*Load font ไทยสาระบรรณ IT 9 Bold Italics*/}</span>
                    <span className="font-thsarabunit9-italic">{/*Load font ไทยสาระบรรณ IT 9 Italics*/}</span>
                </div>
            </Scrollbars>
        </aside>
    );
};

export default Navigation;
