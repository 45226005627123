import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web';
import {Button, ButtonGroup, Dropdown, Image, Modal} from 'react-bootstrap';
import {hrApi, mainApi} from '../HttpClient';
import openInNewTab from './util/openInNewTab';
import packageJson from '../../package.json';
import {useDispatch, useSelector} from 'react-redux'
import {
    setSignature,
    setSignatureCertificateList,
    setUserLoginDetail,
    setUserLoginManagerAdmin,
    setUserLoginExpertAdmin,
    setUserLoginOrganizationSpecial
} from '../app/stores/features/userLoginSlice'
import { clearDocumentRunningNo, setDocumentBy, setDocumentByEmployee, setDocumentByRole } from "../app/stores/features/documentSlice";
import isRole from "./util/isRole";

const Topnav = () => {
    let history = useHistory();
    const [keycloak] = useKeycloak();

    const [infoModal, setInfoModal] = useState(false);
    const infoClose = () => setInfoModal(false);
    const infoShow = () => setInfoModal(true);

    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userLogin.value);
    const managerAdminList = useSelector((state) => state.userLogin.managerAdminList);
    const expertAdminList = useSelector((state) => state.userLogin.expertAdminList);
    const organizationSpecialList = useSelector((state) => state.userLogin.organizationSpecialList);
    const documentState = useSelector((state) => state.document);

    const getInfo = () => {
        infoShow();
    };

    // const checknameSameOrg = (name, orgName) => {
    //     let display = '';
    //     if (String(name).trim() === String(orgName).trim()) {
    //         display = 'none';
    //     }
    //     return display;
    // };

    useEffect(() => {
        console.log("organizationSpecialList",organizationSpecialList);
        let userName = keycloak.tokenParsed.preferred_username;
        let role = keycloak.tokenParsed.resource_access[keycloak.clientId].roles;
        hrApi.get(`/api/employee/${userName}`).then((res) => {
            dispatch(setUserLoginDetail(res.data.data))
            dispatch(setDocumentByEmployee({
                id: res.data.data.employee_id,
                name: res.data.data.fname + ' ' + res.data.data.lname,
                type: 'employee',
            }));
        });
        mainApi.get(`/api/employee/set-instance?userName=${userName}`).then((res) => {
            if (role.includes('super-admin')){
                dispatch(setDocumentByRole({
                    id: '1802',
                    name: 'สารบรรณกรมควบคุมโรค',
                    type: 'bu1',
                }));
            } else if (role.includes('admin')){
                dispatch(setDocumentByRole({
                    id: "" + res.data.orgAllLevel.workBu1Id,
                    name: res.data.orgAllLevel.workBu1Name,
                    type: 'bu1',
                }));
            } else if (role.includes('admin-dep')){
                dispatch(setDocumentByRole({
                    id: "" + res.data.orgAllLevel.workBu2Id,
                    name: res.data.orgAllLevel.workBu2Name,
                    type: 'bu2',
                }));
            } else if (role.includes('admin-sup-dep')){
                dispatch(setDocumentByRole({
                    id: "" + res.data.orgAllLevel.workBu3Id,
                    name: res.data.orgAllLevel.workBu3Name,
                    type: 'bu3',
                }));
            } else {
                dispatch(setDocumentByEmployee({
                    id: res.data.employeeId,
                    name: res.data.fname  + ' ' + res.data.lname,
                    type: 'employee',
                }));
            }
        });
        mainApi.get(`/api/employee/organization/1/${userName}`).then((res) => {
            dispatch(setUserLoginDetail({org: res.data}))
        });
        mainApi.get(`/api/v1/user/signature`).then((res) => {
            dispatch(setSignature(res.data.signature))
        });
        mainApi.get(`/api/v1/user/manager-by-admin-id`).then((res) => {
            dispatch(setUserLoginManagerAdmin(res.data))
        });
        mainApi.get(`/api/v1/user/expert-by-admin-id`).then((res) => {
            dispatch(setUserLoginExpertAdmin(res.data))
        });
        mainApi.get(`/api/signature-api/certificate-list`).then((res) => {
            dispatch(setSignatureCertificateList(res.data))
        });
        mainApi.get(`/api/employee/organization-all-level`).then((res) => {
            dispatch(setUserLoginDetail({orgAllLevel: res.data}))
        });
        mainApi.get(`/api/employee/organization-special-list`).then((res) => {
            dispatch(setUserLoginOrganizationSpecial(res.data))
        });
        dispatch(setUserLoginDetail({role: keycloak.tokenParsed.resource_access[keycloak.clientId].roles}));
        history.push('/');
    }, [keycloak, dispatch, history]);

    const updateDocumentBySelect = (data) => {
        dispatch(clearDocumentRunningNo());
        dispatch(setDocumentBy(data));
        history.push('/');
    }

    const getSignInAs = () => {
        if (['manager', 'expert'].includes(documentState.documentBy.type)) {
            return documentState.documentBy.name.split(' ')[0];
        }
        return documentState.documentBy.name;
    }

    const getSubSignInAs = () => {
        if (documentState.documentBy.type === 'employee') {
            return userLogin.org.name;
        } else if (['manager', 'expert'].includes(documentState.documentBy.type)) {
            let nameArr = documentState.documentBy.name.split(' ');
            return nameArr[1] + ' ' + nameArr[2];
        }
        return "";
    }

    const GenDocumentByItem = ({id, name, type, affixName}) => {
        return (
            <Dropdown.Item className="dropdown-transparent" href="#">
                <div onClick={(e) => updateDocumentBySelect({
                    id: id,
                    name: name,
                    type: type,
                })}>
                    <div className={`document-by ${id === documentState.documentBy.id ? "active" : ""}`}>
                        {name} {affixName ? ' ' + affixName : ''}
                    </div>
                </div>
            </Dropdown.Item>
        );
    }

    return (
        <nav className="navbar navbar-expand-xl bg-color fixed-top hk-navbar box-main-shadow">
            <Link to="#" className="navbar-brand navbar-link-color" data-widget="pushmenu" role="button">
                <span className="nav-text">
                    <i className="fas fa-bars navbar-fa-size"></i>
                    <Image className="ml-3 d-xl-inline-block" src="img/logo-small.png" style={{width: '40px'}}/>
                    <span className="ml-2 d-xl-none nav-text text-shadow-header">ระบบสารบรรณอิเล็กทรอนิกส์ {process.env.REACT_APP_TITLE || ''}</span>
                </span>
            </Link>

            <div className="d-none d-xl-block w-100">
                <div className="d-flex justify-content-center">
                    <span className="nav-text text-shadow-header">
                        ระบบสารบรรณอิเล็กทรอนิกส์ {process.env.REACT_APP_TITLE || ''}
                    </span>
                </div>
            </div>

            <Dropdown as={ButtonGroup} size="sm" className="btn-transparent">
                <Button className="btn-transparent" style={{cursor: "context-menu"}}>
                    <div style={{whiteSpace: "nowrap"}} className="over-name ml-auto">
                        <i className="fas fa-user-circle fa-lg mr-2"></i>
                        {getSignInAs()}
                    </div>
                    <div style={{whiteSpace: "nowrap"}} className="over-org-name text-right">
                        {getSubSignInAs()}
                    </div>
                </Button>

                <Dropdown.Toggle className="btn-transparent" split id="dropdown-split-basic"/>

                <Dropdown.Menu>
                    <Dropdown.Item className="dropdown-transparent" href="#" disabled>
                        <div className="font-13">ลงชื่อเข้าใช้เป็น</div>
                    </Dropdown.Item>
                    <GenDocumentByItem
                        id={userLogin.employee_id}
                        name={userLogin.fname + ' ' + userLogin.lname}
                        type="employee"
                    />
                    {isRole(['super-admin']) && (
                        <GenDocumentByItem
                            id="1802"
                            name="สารบรรณกรมควบคุมโรค"
                            type="bu1"
                            affixName="(นายทะเบียนสำนัก/กอง)"
                        />
                    )}
                    {isRole(['admin']) && userLogin.orgAllLevel.workBu1Id && (
                        <GenDocumentByItem
                            id={"" + userLogin.orgAllLevel.workBu1Id}
                            name={userLogin.orgAllLevel.workBu1Name}
                            type="admin"
                            affixName="(นายทะเบียนสำนัก/กอง)"
                        />
                    )}
                    {isRole(['admin-dep', 'admin-dep-out']) && userLogin.orgAllLevel.workBu2Id && (
                        <GenDocumentByItem
                            id={"" + userLogin.orgAllLevel.workBu2Id}
                            name={userLogin.orgAllLevel.workBu2Name}
                            type="bu2"
                            affixName="(นายทะเบียนกลุ่ม)"
                        />
                    )}
                    {isRole(['admin-sup-dep']) && userLogin.orgAllLevel.workBu3Id && (
                        <GenDocumentByItem
                            id={"" + userLogin.orgAllLevel.workBu3Id}
                            name={userLogin.orgAllLevel.workBu3Name}
                            type="bu3"
                            affixName="(นายทะเบียนงาน)"
                        />
                    )}
                    {/*{isRole(['admin-sup-dep']) && userLogin.orgAllLevel.workBu4Id && (*/}
                    {/*    <GenDocumentByItem*/}
                    {/*        id={"" + userLogin.orgAllLevel.workBu4Id}*/}
                    {/*        name={userLogin.orgAllLevel.workBu4Name}*/}
                    {/*        type="bu4"*/}
                    {/*        affixName="(นายทะเบียนงาน)"*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{isRole(['admin-sup-dep']) && userLogin.orgAllLevel.workBu5Id && (*/}
                    {/*    <GenDocumentByItem*/}
                    {/*        id={"" + userLogin.orgAllLevel.workBu5Id}*/}
                    {/*        name={userLogin.orgAllLevel.workBu5Name}*/}
                    {/*        type="bu5"*/}
                    {/*        affixName="(นายทะเบียนงาน)"*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{isRole(['admin-sup-dep']) && userLogin.orgAllLevel.workBu6Id && (*/}
                    {/*    <GenDocumentByItem*/}
                    {/*        id={"" + userLogin.orgAllLevel.workBu6Id}*/}
                    {/*        name={userLogin.orgAllLevel.workBu6Name}*/}
                    {/*        type="bu6"*/}
                    {/*        affixName="(นายทะเบียนงาน)"*/}
                    {/*    />*/}
                    {/*)}*/}
                    <Dropdown.Divider/>
                    {organizationSpecialList.length > 0 && (
                        <>
                            <Dropdown.Item className="dropdown-transparent" href="#" disabled>
                                <div className="font-13">เอกสารหน่วยงานพิเศษ</div>
                            </Dropdown.Item>
                            {organizationSpecialList.map((item, index) => {
                                return (
                                    <GenDocumentByItem
                                        key={index}
                                        id={item.orgId}
                                        name={item.name}
                                        type="special-org"
                                        affixName={item.workBu1Name}
                                    />
                                )
                            })}
                            <Dropdown.Divider/>
                        </>
                    )}
                    {managerAdminList.length > 0 && (
                        <>
                            <Dropdown.Item className="dropdown-transparent" href="#" disabled>
                                <div className="font-13">เอกสารผู้บริหาร</div>
                            </Dropdown.Item>
                            {managerAdminList.map((item, index) => {
                                return (
                                    <GenDocumentByItem
                                        key={index}
                                        id={'manager_' + item.employeeId}
                                        name={item.name}
                                        type="manager"
                                    />
                                )
                            })}
                            <Dropdown.Divider/>
                        </>
                    )}
                    {expertAdminList.length > 0 && (
                        <>
                            <Dropdown.Item className="dropdown-transparent" href="#" disabled>
                                <div className="font-13">เอกสารผู้ทรงคุณวุฒิ</div>
                            </Dropdown.Item>
                            {expertAdminList.map((item, index) => {
                                return (
                                    <GenDocumentByItem
                                        key={index}
                                        id={'expert_' + item.employeeId}
                                        name={item.name}
                                        type="expert"
                                    />
                                )
                            })}
                            <Dropdown.Divider/>
                        </>
                    )}
                    <Dropdown.Item className="dropdown-transparent" href="#">
                        <div className="d-flex" onClick={(e) => getInfo()}>
                            <div className="mr-2">
                                <i className="fas fa-user dropdown-icon"></i>
                            </div>
                            <div className="font-16">ข้อมูลส่วนตัว</div>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                        <div
                            className="d-flex"
                            onClick={() => {
                                openInNewTab(keycloak.authServerUrl + '/realms/' + keycloak.realm + '/account');
                            }}
                        >
                            <div className="mr-2">
                                <i className="fas fa-cog dropdown-icon"></i>
                            </div>
                            <div className="font-16">กำหนดค่า</div>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item href="#">
                        <div className="d-flex" onClick={(e) => keycloak.logout()}>
                            <div className="mr-2">
                                <i className="fas fa-power-off dropdown-icon"></i>
                            </div>
                            <div className="font-16">ออกจากระบบ</div>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={infoModal} onHide={infoClose} size="md" centered>
                <div className="card-header">
                    <div className="media align-items-center">
                        <div className="d-flex mr-15">
                            <div className="avatar avatar-sm">
                                <i className="fas fa-3x fa-user-circle label-color-dark"></i>
                            </div>
                        </div>
                        <div className="media-body text-left margin-left-15">
                            <div className="text-capitalize font-weight-500 text-dark">
                                <span>{userLogin.fname} {userLogin.lname}</span>
                            </div>
                            <div className="subtitle-profile">
                                <span>{userLogin.email}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <div>
                                <span className="label label-dark">สิทธิ์:</span>
                                <span className="ml-3 text-dark font-13-force">
                                    {userLogin.role.map((role, index) => {
                                        let roleText = '';
                                        switch (role) {
                                            case 'super-admin':
                                                roleText = 'Admin กรม';
                                                break;
                                            case 'admin':
                                                roleText = 'Admin สำนัก/กอง';
                                                break;
                                            case 'admin-dep':
                                                roleText = 'Admin กลุ่มงาน';
                                                break;
                                            case 'admin-dep-out':
                                                roleText = 'Admin กลุ่มงาน เพิ่มเมนูภายนอก';
                                                break;
                                            case 'admin-sup-dep':
                                                roleText = 'Admin งาน';
                                                break;
                                            case 'out':
                                                roleText = 'เปิดเมนูภายนอก';
                                                break;
                                            case 'secret-doc':
                                                roleText = 'เมนูเอกสารลับ';
                                                break;
                                            case 'user':
                                                roleText = 'ผู้ใช้งาน';
                                                break;
                                            default:
                                                roleText = 'ผู้ใช้งาน';
                                                break;
                                        }
                                        if (index > 0) {
                                            return ', ' + roleText;
                                        }
                                        return roleText;
                                    })}
                                </span>
                            </div>
                            <div className="mt-2">
                                <span className="label label-dark">เวอร์ชัน:</span>
                                <span className="ml-2 text-dark font-13-force">
                                    {packageJson.version}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </nav>
    );
};

export default Topnav;
