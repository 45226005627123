import { mainApi } from '../../HttpClient';

export const getApiMasUrgencyLevel = async () =>{
  let rs = await mainApi.get('/api/mas_urgency_level/list');
  return rs;
} 

export const getApiMasSecretLevel = async () =>{
  let rs = await mainApi.get('/api/mas_secret_level/list');
  return rs;
} 

export const getApiMasSectionLevel = async () =>{
  let rs = await mainApi.get('/api/mas_section_level/list');
  return rs;
} 

export const showMasUrgencyLevelName = (urgenName) =>{
  let res = urgenName;
  let txtArr = urgenName.split("-");
  if(txtArr.length === 1){
    res = txtArr[1];
  }
  return res;
}