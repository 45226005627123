import React, { useState, useEffect } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { mainApi } from "../../HttpClient";
import moment from "moment";
import fileDownload from "js-file-download";

import { getDateThaiShortFormat, getYearThaiShortFormat } from "../util/Date";

const RptSummaryByMonth = () => {
  const [keycloak] = useKeycloak();
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [selectMonth, setSelectMonth] = useState("");
  const [listMonth, setlistMonth] = useState([]);
  const [listYear, setlistYear] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const documentState = useSelector((state) => state.document);

  useEffect(() => {
    setIsDataLoading(true);
    const fecthData = async () => {
      if(selectMonth == ""){
        let res = await mainApi.get(`/api/report/destruction/data/${selectYear}/${documentState.documentBy.id}`);
        if (res.status === 200) {
          setDataTable(res.data);
        } else {
          setDataTable([]);
        }
        setIsDataLoading(false);
      }else{
        let res = await mainApi.get(`/api/report/destruction/data/${selectYear}/${selectMonth}/${documentState.documentBy.id}`);
        if (res.status === 200) {
          setDataTable(res.data);
        } else {
          setDataTable([]);
        }
        setIsDataLoading(false);
      }
      
    };
    fecthData();

    const months = [
      {
        key: "01",
        month: "มกราคม",
      },
      {
        key: "02",
        month: "กุมภาพันธ์",
      },
      {
        key: "03",
        month: "มีนาคม",
      },
      {
        key: "04",
        month: "เมษายน",
      },
      {
        key: "05",
        month: "พฤษภาคม",
      },
      {
        key: "06",
        month: "มิถุนายน",
      },
      {
        key: "07",
        month: "กรกฎาคม",
      },
      {
        key: "08",
        month: "สิงหาคม",
      },
      {
        key: "09",
        month: "กันยายน",
      },
      {
        key: "10",
        month: "ตุลาคม",
      },
      {
        key: "11",
        month: "พฤศจิกายน",
      },
      {
        key: "12",
        month: "ธันวาคม",
      },
    ];
    setlistMonth(months);

  }, [keycloak, selectYear,selectMonth]);

 

  

  const addYear = (currentYr, currentArr) => {
    currentArr.push({
      label: getYearThaiShortFormat(currentYr),
      value: currentYr.format("YYYY"),
    });
  };

  const renderYear = () => {
    let thisYear = new Date().getFullYear();
    let endLoop = thisYear + 11;
    let options = [];
    for (let loopYear = thisYear - 4; loopYear < endLoop; loopYear++) {
      options.push(
        <option key={loopYear} value={loopYear}>
          {loopYear + 543} {thisYear === loopYear && "(ปีปัจจุบัน)"}
        </option>
      );
    }
    return options;
  };

  function renderMonth () {
    const months = [
      {
        key: "01",
        month: "มกราคม",
      },
      {
        key: "02",
        month: "กุมภาพันธ์",
      },
      {
        key: "03",
        month: "มีนาคม",
      },
      {
        key: "04",
        month: "เมษายน",
      },
      {
        key: "05",
        month: "พฤษภาคม",
      },
      {
        key: "06",
        month: "มิถุนายน",
      },
      {
        key: "07",
        month: "กรกฎาคม",
      },
      {
        key: "08",
        month: "สิงหาคม",
      },
      {
        key: "09",
        month: "กันยายน",
      },
      {
        key: "10",
        month: "ตุลาคม",
      },
      {
        key: "11",
        month: "พฤศจิกายน",
      },
      {
        key: "12",
        month: "ธันวาคม",
      },
    ];
    // return months.map((month, index) => (
    //   <option key={index} value={index.key}>
    //     {index.month}
    //   </option>
    // ));
    return months;
  };


  const handlePrint = () => {
    setIsExportFile(true);
    if(selectMonth != ""){
      mainApi
      .get(`/api/report/destruction/pdf/${selectYear}/${selectMonth}/${documentState.documentBy.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(
          res.data,
          `บัญชีหนังสือขอทำลายประจำปี ${selectYear + 543}.pdf`
        );
        setIsExportFile(false);
      });
    }else{
      mainApi
      .get(`/api/report/destruction/pdf/${selectYear}/${documentState.documentBy.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(
          res.data,
          `บัญชีหนังสือขอทำลายประจำปี ${selectYear + 543}.pdf`
        );
        setIsExportFile(false);
      });
    }
    
  };

  const renderReport = () => {
    if (isDataLoading) {
      return (
        <tr>
          <td colSpan="5">
            <div
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
      );
    } else if (dataTable.length === 0) {
      return (
        <tr>
          <td colSpan="5">ไม่พบข้อมูล</td>
        </tr>
      );
    } else {
      return dataTable.map((item, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.registerNo}</td>
          <td>{getDateThaiShortFormat(item.documentDate)}</td>
          <td>{item.receiveNo}</td>
          <td>{item.subject}</td>
          {/* <td>{item.sentDate}</td>
          <td>{item.receiveDate}</td>
          <td>
            <input
              className="btn-category-color"
              style={{
                backgroundColor: `#${item.categoryColor || ""}`,
                textAlign: "center",
              }}
              data-toggle="tooltip"
              data-placement="top"
              title={item.categoryName || "ไม่มีหมวด"}
              value={item.destructionYear || 1}
              readOnly
            />
          </td>
          <td>{}</td>
          <td>{}</td> */}
        </tr>
      ));
    }
  };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "100vh" }}>
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    รายงานหนังสือเพื่อทำลาย
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb">
                      <Link to="./report" className="text-breadcrumb">
                        รายงาน
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      รายงานหนังสือเพื่อทำลาย
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <div className="row">
                    <div className="col-12 col-md-4 pt-1">
                      <select
                        className="form-control shadow-none"
                        name="listYear"
                        onChange={(e) => setSelectYear(e.target.value)}
                        value={selectYear}
                      >
                        {renderYear()}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 pt-1">
                      <select
                        className="form-control shadow-none"
                        name="listMonth"
                        onChange={(e) => setSelectMonth(e.target.value)}
                        value={selectMonth}
                      >
                        <option value="">==== เลือก เดือน ====</option>
                        {listMonth.map((item) => (
                            <option key={item.key} value={item.key}>
                                {item.month}
                            </option>
                        ))}
                        ;
                      </select>
                    </div>
                    <div className="col-12 col-md-4 pt-1">
                      <Button
                        type="button"
                        variant="contained"
                        className="btn-no-border btn-color-add-small"
                        disabled={isExportFile}
                        onClick={!isExportFile ? handlePrint : null}
                      >
                        <i className="fas fa-print pr-2"></i>
                        ดาวน์โหลดเอกสาร
                        {isExportFile && (
                          <div
                            className="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pt-2">
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                        className="text-center"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>ลำดับที่</th>
                            <th>ที่</th>
                            <th>ลงวันที่</th>
                            <th>เลขทะเบียนรับ</th>
                            <th>เรื่อง</th>
                            {/* <th>วันที่ส่ง</th>
                            <th>วันที่รับ</th>
                            <th>หมวด</th>
                            <th>ประเภท</th>
                            <th>จัดการ</th> */}
                          </tr>
                        </thead>
                        <tbody>{renderReport()}</tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default RptSummaryByMonth;
