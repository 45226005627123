import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Nav, Row, Tab, } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FroalaEditor from "../share/FroalaEditor";
import UploadFiles from "../share/UploadFiles";
import { mainApi } from "../../HttpClient";
import DateInput from "../share/DateInput";
import moment from "moment";
import { getCurrentDocRunning } from "../util/DocRegister";
import Swal from "sweetalert2";
import config from "react-global-configuration";
import FormAutoPreviewModal from "../share/pdf-preview/PdfPreviewModal";
import { useSelector } from "react-redux";
import { unit } from "./util/Unit";
import templateHtmlFormAutoGenreIn from "./pdf/templateHtmlFormAutoGenreIn";
import templateHtmlFormAutoGenreOut from "./pdf/templateHtmlFormAutoGenreOut";
import templateHtmlFormAutoId17 from "./pdf/templateHtmlFormAutoId17";
import { urgencyLevel } from "./util/UrgencyLevel";
import OrgEmpListAuto from '../share/OrgEmpListAuto';
import { informList } from "./util/InformList";
import CreatableSelect from 'react-select/creatable';


export default function FormAuto() {
    const {register, handleSubmit, setValue, getValues, reset,} = useForm();

    const [content, setContent] = useState("");
    const [content2, setContent2] = useState("");
    const [fileList, setFileList] = useState([]);
    const [openTab2, setOpenTab2] = useState(false);
    // const [autoFormDate, setAutoFormDate] = useState(null);
    // const [f2AutoFormDate, setF2AutoFormDate] = useState(null);
    const [autoFormDueDate, setAutoFormDueDate] = useState(null);
    const [f2AutoFormDueDate, setF2AutoFormDueDate] = useState(null);
    const [docRunningNo, setDocRunningNo] = useState(1);
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);

    const [autoFormName, setAutoFormName] = useState('');

    const tab1Ref = useRef(null);
    const tab2Ref = useRef(null);
    const handlePdfPreviewRef = useRef(null);

    const history = useHistory();
    const location = useLocation();

    const documentState = useSelector((state) => state.document);

    const [showSelectDepartment, setShowSelectDepartment] = useState(false);
    const orgEmpListAutoRef = useRef();
    const orgEmpListAutoRef2 = useRef();

    const [departmentList, setDepartmentList] = useState([]);
    const [f2DepartmentList, setF2DepartmentList] = useState([]);

    const [departmentNameList, setDepartmentNameList] = useState([]);
    const [f2DepartmentNameList, setF2DepartmentNameList] = useState([]);

    const [listType, setListType] = useState();
    const [f2ListType, setF2ListType] = useState();

    const [prefixInform, setPrefixInform] = useState(informList[0]);
    const [f2PrefixInform, setF2PrefixInform] = useState(informList[0]);
    const [referenceList, setReferenceList] = useState([]);
    const [f2ReferenceList, setF2ReferenceList] = useState([]);
    const [f2AttachList, setF2AttachList] = useState([]);
    const [attachList, setAttachList] = useState([]);

    useEffect(() => {
        setIsLoadingDocRunningNo(true);
        if (!location.state || !location.state.formId) {
            if (!documentState.documentBy || documentState.documentBy.type === 'employee' || documentState.documentBy.type === 'manager') {
                history.push('./dashbordV2');
                return;
            }
            history.push('./form-auto-menu-list');
            return;
        }

        const {name: documentByName} = documentState.documentBy;
        reset({
            government: documentByName,
            governmentFt: documentByName,
            f2Government: documentByName,
            f2GovernmentFt: documentByName,
        });

        let initContent = "";
        switch (location.state.formId) {
            case 1:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายใน)');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดย...(สำนัก/กอง)...กำหนดจัดประชุมเชิงปฏิบัติการ...(ชื่อประชุม)... วัน...เดือน...ปี.. ณ สถานที่ที่จัดประชุม...เพื่อ ................................................</p>' +
                    '<p>กรมควบคุมโรค ขอเชิญ ท่าน... เป็นวิทยากรบรรยาย/อภิปรายให้ความรู้ เรื่อง....ในวันที่ ...เดือน...ปี... เวลา...น. โดยมีรายละเอียดกำหนดการประชุมที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดให้ความอนุเคราะห์ต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 2:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดย...(สำนัก/กอง)...กำหนดจัดประชุมเชิงปฏิบัติการ เรื่อง...(ชื่อประชุม)... ระหว่างวันที่...เดือน...ปี../ในวันที่...เดือน...ปี... ณ ห้องประชุม............. ชั้น... อาคาร... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ ...(วัตถุประสงค์ของโครงการ).....</p>' +
                    '<p>กรมควบคุมโรค พิจารณาแล้วเห็นว่า ท่าน เป็นผู้มีความรู้...(ในด้านที่จะขอเชิญ มาบรรยาย)... เป็นอย่างดี จึงขอเชิญไปเป็นวิทยากรบรรยายให้ความรู้ เรื่อง............................ ในวันที่ ....................... เวลา ................น. ณ ห้องประชุม..... ชั้น.... อาคาร... กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังสำนัก/กอง........หรือไปรษณีย์อิเล็กทรอนิกส์ ...(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ..... ผู้ประสาน นาย/นาง/นางสาว.......................................... โทรศัพท์เคลื่อนที่ ......................... รายละเอียดตามสิ่งที่ส่งมาด้วย</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณารับเชิญเป็นวิทยากรในการประชุมดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 3:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดย...(สำนัก/กอง)...กำหนดจัดประชุมเชิงปฏิบัติการ เรื่อง...(ชื่อประชุม)... ระหว่างวันที่...เดือน...ปี../ในวันที่...เดือน...ปี... ณ ห้องประชุม............. ชั้น...อาคาร...กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ ...(วัตถุประสงค์ของโครงการ).....</p>' +
                    '<p>กรมควบคุมโรค พิจารณาแล้วเห็นว่า ท่าน เป็นผู้มีความรู้...(ในด้านที่จะขอเชิญมาบรรยาย)... เป็นอย่างดี จึงขอเชิญไปเป็นวิทยากรบรรยายให้ความรู้ เรื่อง............................ ในวันที่ ....................... เวลา ................น. ณ ห้องประชุม..... ชั้น.... อาคาร... กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังสำนัก/กอง........หรือไปรษณีย์อิเล็กทรอนิกส์ ...(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ..... ผู้ประสาน นาย/นาง/นางสาว.......................................... โทรศัพท์เคลื่อนที่ ......................... รายละเอียดตามสิ่งที่ส่งมาด้วย</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณารับเชิญเป็นวิทยากรในการประชุมดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 4:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก)');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดย...(สำนัก/กอง)...กำหนดจัดประชุมเชิงปฏิบัติการ เรื่อง...(ชื่อประชุม)... ระหว่างวันที่...เดือน...ปี../ในวันที่...เดือน...ปี... ณ ห้องประชุม............. ชั้น... อาคาร... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ ...(วัตถุประสงค์ของโครงการ).....</p>' +
                    '<p>กรมควบคุมโรค พิจารณาแล้วเห็นว่า ท่าน เป็นผู้มีความรู้...(ในด้านที่จะขอเชิญ มาบรรยาย)... เป็นอย่างดี จึงขอเชิญไปเป็นวิทยากรบรรยายให้ความรู้ เรื่อง............................ ในวันที่ ....................... เวลา ................น. ณ ห้องประชุม..... ชั้น.... อาคาร... กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังสำนัก/กอง........หรือไปรษณีย์อิเล็กทรอนิกส์ ...(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ..... ผู้ประสาน นาย/นาง/นางสาว.......................................... โทรศัพท์เคลื่อนที่ ......................... รายละเอียดตามสิ่งที่ส่งมาด้วย</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณารับเชิญเป็นวิทยากรในการประชุมดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 5:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายใน) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดยสำนัก/กอง... กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ ....(ชื่อประชุม).............ระหว่างวันที่ ......... /ในวันที่ .........ณ ห้องประชุม........ ชั้น.......อาคาร..... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ.............(วัตถุประสงค์ของโครงการ กิจกรรม)............</p>' +
                    '<p>กรมควบคุมโรค พิจารณาแล้วเห็นว่า นาย/นาง/นางสาว................................. ตำแหน่ง.............. ซึ่งเป็นผู้มีความรู้ ความสามารถ...(ในด้านที่จะขอเชิญมาบรรยาย)................... เป็นอย่างดี จึงขอความอนุเคราะห์วิทยากรบรรยายให้ความรู้ เรื่อง ............................................ ในวันที่ .........................เวลา ........น. ณ ห้องประชุม.......... ชั้น.... อาคาร........ กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกลุ่ม........................................................ โทรสาร................. หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)......... โดยมี นาย/นาง/นางสาว..........................................โทรศัพยท์เคลื่อนที่.......................................... เป็นผู้ประสานงาน รายละเอียดตามเอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดให้ความอนุเคราะห์ต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 6:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายใน) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยสำนัก/กอง...... กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ ....(ชื่อประชุม)...... ระหว่างวันที่ ............ /ในวันที่ .............. ณ ห้องประชุม........ ชั้น....... อาคาร..... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ.............(วัตถุประสงค์ของโครงการ/กิจกรรม)...................................</p>' +
                    '<p>สำนัก/กอง......... พิจารณาแล้วเห็นว่า นาย/นาง/นางสาว............................... ตำแหน่ง.............. ซึ่งเป็นผู้มีความรู้ ความสามารถ...(ในด้านที่จะขอเชิญมาบรรยาย)................... เป็นอย่างดี จึงขอความอนุเคราะห์วิทยากรบรรยายให้ความรู้ เรื่อง ............................................ ในวันที่ .........................เวลา ........น. ณ ห้องประชุม.......... ชั้น.... อาคาร........ กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกลุ่ม........................................................ โทรสาร................. หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)......... โดยมี นาย/นาง/นางสาว..........................................โทรศัพยท์เคลื่อนที่..........................................เป็นผู้ประสานงาน รายละเอียดตามเอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดให้ความอนุเคราะห์ต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 7:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายนอก) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดยสำนัก/กอง... กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ ....(ชื่อประชุม).............ระหว่างวันที่ ......... /ในวันที่ .........ณ ห้องประชุม........ ชั้น.......อาคาร..... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ.............(วัตถุประสงค์ของโครงการ กิจกรรม)............</p>' +
                    '<p>กรมควบคุมโรค พิจารณาแล้วเห็นว่า นาย/นาง/นางสาว................................. ตำแหน่ง.............. ซึ่งเป็นผู้มีความรู้ ความสามารถ...(ในด้านที่จะขอเชิญมาบรรยาย)................... เป็นอย่างดี จึงขอความอนุเคราะห์วิทยากรบรรยายให้ความรู้ เรื่อง ............................................ ในวันที่ .........................เวลา ........น. ณ ห้องประชุม.......... ชั้น.... อาคาร........ กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกลุ่ม........................................................ โทรสาร................. หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)......... โดยมี นาย/นาง/นางสาว..........................................โทรศัพยท์เคลื่อนที่.......................................... เป็นผู้ประสานงาน รายละเอียดตามเอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาให้ความอนุเคราะห์วิทยากรในการประชุมดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 8:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยสำนัก/กอง...... กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ ....(ชื่อประชุม)......ระหว่างวันที่ ............ /ในวันที่ .............. ณ ห้องประชุม........ ชั้น....... อาคาร..... กรมควบคุมโรค กระทรวงสาธารณสุข เพื่อ.............(วัตถุประสงค์ของโครงการ/กิจกรรม)...................................</p>' +
                    '<p>สำนัก/กอง......... พิจารณาแล้วเห็นว่า นาย/นาง/นางสาว............................... ตำแหน่ง.............. ซึ่งเป็นผู้มีความรู้ ความสามารถ...(ในด้านที่จะขอเชิญมาบรรยาย)................... เป็นอย่างดี จึงขอความอนุเคราะห์วิทยากรบรรยายให้ความรู้ เรื่อง ............................................ ในวันที่ .........................เวลา ........น. ณ ห้องประชุม.......... ชั้น.... อาคาร........ กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกลุ่ม........................................................ โทรสาร................. หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)......... โดยมี นาย/นาง/นางสาว..........................................โทรศัพยท์เคลื่อนที่.......................................... เป็นผู้ประสานงาน รายละเอียดตามเอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาให้ความอนุเคราะห์วิทยากรในการประชุมดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 9:
                setAutoFormName('ขอเชิญประชุม (ภายใน) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดยสำนัก/กอง..... กำหนดจัดประชุมเชิงปฏิบัติการ/ประชุม .....(ชื่อประชุม)..... ครั้งที่ ........ ในวันที่................ เพื่อ.......(วัตถุประสงค์ของการจัดประชุม)............................................................................................................................................</p>' +
                    '<p>กรมควบคุมโรค ขอเชิญ ท่าน/ผู้เกี่ยวข้อง/ผู้ที่ได้รับมอบหมาย เข้าร่วมประชุม ในวันที่.............. เวลา.......... น. ณ ห้องประชุม.... ชั้น.... อาคาร.... กรมควบคุมโรค รายละเอียด ตามกำหนดการประชุม/ระเบียบวาระการประชุม/เอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วมประชุม/แจ้งผู้เกี่ยวข้องเข้าร่วมประชุม ตาม วัน เวลาและสถานที่ดังกล่าว จะเป็นพระคุณ</p>';
                break;
            case 10:
                setAutoFormName('ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยสำนัก/กอง..... กำหนดจัดประชุมเชิงปฏิบัติการ/ประชุม .....(ชื่อประชุม)..... ครั้งที่ ........ ในวันที่................ เพื่อ.......(วัตถุประสงค์ของการจัดประชุม)..... .......................................................................................................................................</p>' +
                    '<p>สำนัก/กอง......... ขอเชิญ ท่าน/ผู้เกี่ยวข้อง/ผู้ที่ได้รับมอบหมาย เข้าร่วมประชุม ในวันที่.............. เวลา.......... น. ณ ห้องประชุม.... ชั้น.... อาคาร.... กรมควบคุมโรค รายละเอียด ตามกำหนดการประชุม/ระเบียบวาระการประชุม/เอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วมประชุม/แจ้งผู้เกี่ยวข้องเข้าร่วมประชุม ตาม วัน เวลาและสถานที่ดังกล่าว จะเป็นพระคุณ</p>';
                break;
            case 11:
                setAutoFormName('ขอเชิญประชุม (ภายใน-เวียน) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดยสำนัก/กอง..... กำหนดจัดประชุมเชิงปฏิบัติการ/ประชุม .....(ชื่อประชุม)..... ครั้งที่ ........ ในวันที่................ เพื่อ.......(วัตถุประสงค์ของการจัดประชุม)..... .......................................................................................................................................</p>' +
                    '<p>กรมควบคุมโรค ขอเชิญ ท่าน/ผู้เกี่ยวข้อง/ผู้ที่ได้รับมอบหมาย เข้าร่วมประชุม ในวันที่.............. เวลา.......... น. ณ ห้องประชุม.... ชั้น.... อาคาร.... กรมควบคุมโรค รายละเอียด ตามกำหนดการประชุม/ระเบียบวาระการประชุม/เอกสารที่แนบมาพร้อมนี้ </p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วมประชุม/แจ้งผู้เกี่ยวข้องเข้าร่วมประชุม ตาม วัน เวลาและสถานที่ดังกล่าว จะเป็นพระคุณ</p>';

                setShowSelectDepartment(true);
                reset({
                    inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)',
                    f2Inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)'
                });
                break;
            case 12:
                setAutoFormName('ขอเชิญประชุม (ภายใน-เวียน) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยสำนัก/กอง..... กำหนดจัดประชุมเชิงปฏิบัติการ/ประชุม .....(ชื่อประชุม)..... ครั้งที่ ........ ในวันที่................ เพื่อ.......(วัตถุประสงค์ของการจัดประชุม)..... .......................................................................................................................................</p>' +
                    '<p>สำนัก/กอง......... ขอเชิญ ท่าน/ผู้เกี่ยวข้อง/ผู้ที่ได้รับมอบหมาย เข้าร่วมประชุม ในวันที่.............. เวลา.......... น. ณ ห้องประชุม.... ชั้น.... อาคาร.... กรมควบคุมโรค รายละเอียด ตามกำหนดการประชุม/ระเบียบวาระการประชุม/เอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วมประชุม/แจ้งผู้เกี่ยวข้องเข้าร่วมประชุม ตาม วัน เวลาและสถานที่ดังกล่าว จะเป็นพระคุณ</p>';

                setShowSelectDepartment(true);
                reset({
                    inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)',
                    f2Inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)'
                });
                break;
            case 13:
                setAutoFormName('ขอเชิญประชุม (ภายนอก) - กรมควบคุมโรค');
                initContent =
                    '<p>ด้วยกรมควบคุมโรค โดย.....สำนัก/กอง........... กำหนดจัดประชุมเชิงปฏิบัติการ เรื่อง...................................เพื่อ.......(วัตถุประสงค์ของโครงการ/กิจกรรม)............................. .......................................................................................................................................</p>' +
                    '<p>กรมควบคุมโรค ขอเชิญ ท่าน/ผู้แทน/ผู้ที่ได้รับมอบหมาย/ผู้เกี่ยวข้องเข้าร่วม ประชุม......................ระหว่างวันที่.............ในวันที่.............. เวลา.......... น. ณ ห้องประชุม....... ชั้น.... อาคาร.... กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกอง/สำนัก ............... โทรสาร ............ หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)...... โดยมี นาย/นาง/นางสาว.......................................โทรศัพท์เคลื่อนที่ ................. เป็นผู้ประสาน รายละเอียดตามสิ่งที่ส่งมาด้วย</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วม/มอบ/หมายผู้แทน/แจ้งผู้ที่เกี่ยวข้องเข้าร่วม ประชุมตามวัน เวลา และสถานที่ดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 14:
                setAutoFormName('ขอเชิญประชุม (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                initContent =
                    '<p>ด้วยสำนัก/กอง.............. กำหนดจัดประชุมเชิงปฏิบัติการ เรื่อง...................... ..........................เพื่อ.......(วัตถุประสงค์ของโครงการ/กิจกรรม).............................................</p>' +
                    '<p>สำนัก/กอง........... ขอเชิญ ท่าน/ผู้แทน/ผู้ที่ได้รับมอบหมาย/ผู้เกี่ยวข้องเข้าร่วม ประชุม......................ระหว่างวันที่.............ในวันที่.............. เวลา.......... น. ณ ห้องประชุม....... ชั้น.... อาคาร.... กรมควบคุมโรค กระทรวงสาธารณสุข ทั้งนี้ โปรดส่งแบบตอบรับไปยังกอง/สำนัก ............... โทรสาร ............ หรือไปรษณีย์อิเล็กทรอนิกส์.....(ไปรษณีย์อิเล็กทรอนิกส์ภาครัฐ)...... โดยมี นาย/นาง/นางสาว.......................................โทรศัพท์เคลื่อนที่ ................. เป็นผู้ประสาน รายละเอียดตามสิ่งที่ส่งมาด้วย</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณาเข้าร่วม/มอบ/หมายผู้แทน/แจ้งผู้ที่เกี่ยวข้องเข้าร่วม  ประชุมตามวัน เวลา และสถานที่ดังกล่าวต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 15:
                setAutoFormName('ขอความอนุเคราะห์ใช้ห้องประชุม');
                initContent =
                    '<p>ด้วยสำนัก/กอง.............. กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ...(ชื่อประชุม) ......................ในวันที่ ................................ เวลา............. น.</p>' +
                    '<p>สำนัก/กอง...........ขอความอนุเคราะห์ใช้ห้องประชุมของ................................. ห้องประชุม........ ชั้น.... อาคาร........... กรมควบคุมโรค เพื่อใช้จัดประชุมดังกล่าว ในวันที่............. ........ เวลา............ น. รายละเอียดตามเอกสารแนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดให้ความอนุเคราะห์ต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 16:
                setAutoFormName('หนังสือเวียนทั่วไปแบบบันทึก');
                initContent =
                    '<p>ด้วยสำนัก/กอง.............. กำหนดจัดประชุม/ประชุมเชิงปฏิบัติการ...(ชื่อประชุม) ......................ในวันที่ ................................ เวลา............. น.</p>' +
                    '<p>สำนัก/กอง...........ขอความอนุเคราะห์ใช้ห้องประชุมของ................................. ห้องประชุม........ ชั้น.... อาคาร........... กรมควบคุมโรค เพื่อใช้จัดประชุมดังกล่าว ในวันที่............. ........ เวลา............ น. รายละเอียดตามเอกสารแนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดให้ความอนุเคราะห์ต่อไปด้วย จะเป็นพระคุณ</p>';

                setShowSelectDepartment(true);
                reset({
                    inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)',
                    f2Inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)'
                });
                break;
            case 17:
                setAutoFormName('หนังสือเวียนประทับตราลงนาม');
                reset({inform: 'หน่วยงานในสังกัดกรมควบคุมโรค', f2Inform: 'หน่วยงานในสังกัดกรมควบคุมโรค'});
                initContent =
                    '<p>กรมควบคุมโรค ขอส่งสำเนาหนังสือกระทรวงสาธารณสุข ที่........... ลงวันที่ วัน...เดือน...ปี.. ขอส่งสำเนาหนังสือสำนักงานปลัดสำนักนายกรัฐมนตรี ที่...............  ลงวันที่ วัน...เดือน...ปี.. เรื่อง ขอความอนุเคราะห์เผยแพร่ความรู้เกี่ยวกับพระราชบัญญัติข้อมูลข่าวสารของราชการ พ.ศ. ๒๕.. รายละเอียดตามเอกสารที่แนบมาพร้อมนี้</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดทราบและแจ้งให้บุคลากรในหน่วยงานทราบต่อไปด้วย จะเป็นพระคุณ</p>';
                break;
            case 18:
                setAutoFormName('หนังสือขออนุมัติเดินทาง');
                initContent =
                    '<p>ตามหนังสือ............................................ ที่ .................................. ลงวันที่ ............................... .........ระบุเหตุผลที่ขอเดินทางไปราชการ.................................................................................. ระหว่างวันที่/ในวันที่ ............................ เวลา ................... ณ ....................................................................  นั้น</p>' +
                    '<p>กระผม/ดิฉัน ..................................... ตำแหน่ง ......................................... ขออนุมัติเดินทางไปราชการ ............................................................................................ ระหว่างวันที่/ในวันที่ ........................ เวลา ............. ณ ............................................. (หรือ ตามวัน เวลา และสถานที่ดังกล่าวข้างต้น)</p>' +
                    '<p>จึงเรียนมาเพื่อโปรดพิจารณา หากเห็นชอบโปรดอนุมัติให้เดินทางไปราชการต่อไปด้วย จะเป็นพระคุณ</p>';

                setShowSelectDepartment(true);
                reset({
                    subject: 'ขออนุมัติเดินทางไปราชการ',
                    inform: 'อธิบดีกรมควบคุมโรค/ผู้อำนวยการ...........(หน่วยงานเทียบเท่ากอง)................/เลขานุการกรม',
                    // f2Inform: 'ผู้อำนวยการในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)/หน่วยงานในสังกัดกรมควบคุมโรค (ตามรายชื่อแนบท้าย)'
                });
                break;
            default:
                initContent = "";
                break;
        }
        
        setContent(initContent);
        setContent2(initContent);

        if (documentState.documentBy.id !== null) {
            getCurrentDocRunning(location.state.autoFormType, documentState.documentBy).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [history, location.state, documentState.documentBy, reset]);

    // useEffect(() => {
    //     if (documentState.documentBy && documentState.documentBy.id !== null) {
    //         reset();
    //         mainApi.post('/api/v2/documents-register/register-name', documentState.documentBy).then((res) => {
    //             let specialChar = '';
    //             if (location.state.autoFormType === "W") {
    //                 specialChar = '/ว';
    //             }
    //             setValue('autoFormNo', res.data + specialChar);
    //         });
    //     }
    // }, [reset, documentState.documentBy, setValue, location.state]);

    const PdfProcess = async (typeId) => {
        handlePdfPreviewRef.current.clearDataAndShowModal();
        let pdfDataList = [];
        let data = getValues();
        data = {
            ...data,

            formId: location.state.formId,
            autoFormName: autoFormName,
            autoFormType: location.state.autoFormType,
            autoFormGenre: location.state.autoFormGenre,
            createInstead: data.createInstead,

            // autoFormDate: moment(autoFormDate).format('YYYY-MM-DD'),
            autoFormDueDate: moment(autoFormDueDate).format('YYYY-MM-DD'),
            // f2AutoFormDate: moment(f2AutoFormDate).format('YYYY-MM-DD'),
            f2AutoFormDueDate: moment(f2AutoFormDueDate).format('YYYY-MM-DD'),

            content: content,
            f2Content: content2,
            prefixInform: prefixInform.value || 'เรียน',
            listType: listType,
            departmentList: departmentList,
            departmentNameList: departmentNameList,
            f2ListType: f2ListType,
            f2DepartmentList: f2DepartmentList,
            f2DepartmentNameList: f2DepartmentNameList,

            attachList: attachList,
            f2AttachList: f2AttachList,
            referenceList: referenceList,
            f2ReferenceList: f2ReferenceList,
        };

        if (f2PrefixInform) {
            data.f2PrefixInform = f2PrefixInform.value || 'เรียน';
        }
        pdfDataList.push({
            fileName: `รูปแบบที่ ${typeId}.pdf`,
            ironPdfHtmlData: getTemplateFormAuto(data, typeId),
        });

        let params = {
            data: pdfDataList,
        }
        mainApi.post('/api/form-auto-report/generate', params).then((res) => {
            handlePdfPreviewRef.current.initPdfList(res.data);
        });
    };

    const getTemplateFormAuto = (data, type) => {
        if (data.formId === 17) {
            return templateHtmlFormAutoId17(data, type);
        } else if (data.autoFormGenre === "IN") {
            return templateHtmlFormAutoGenreIn(data, type);
        } else if (data.autoFormGenre === "OUT") {
            return templateHtmlFormAutoGenreOut(data, type);
        }
        return {};
    };

    const copyDataToFormAuto2 = () => {

        const data = getValues();
        setValue('f2Government', data.government);
        setValue('f2Tel', data.tel);
        setValue('f2Fax', data.fax);
        setValue('f2Subject', data.subject);
        setValue('f2Inform', data.inform);
        setValue('f2PostScript', data.postScript);
        setValue('f2Command', data.command);
        setValue('f2GovernmentFt', data.governmentFt);
        setValue('f2TelFt', data.telFt);
        setValue('f2FaxFt', data.faxFt);
        setValue('f2EmailFt', data.emailFt);


        if (f2ReferenceList.length === 0) {
            setF2ReferenceList(referenceList);
        }

        if (f2AttachList.length === 0) {
            setF2AttachList(attachList);
        }

        // setF2AutoFormDate(autoFormDate);
        setF2AutoFormDueDate(autoFormDueDate);
        setContent2(content);
        setF2ListType(listType);

        setF2DepartmentList(departmentList);
        setF2DepartmentNameList(departmentNameList);

        setF2PrefixInform(prefixInform);

        orgEmpListAutoRef2.current.setDepartment(departmentList);
        orgEmpListAutoRef2.current.setRadioListType(parseInt(listType));

    };

    const handleSaveDraft = (data) => {
        let bodyData = {
            formId: location.state.formId,
            autoFormName: autoFormName,
            autoFormType: location.state.autoFormType,
            autoFormGenre: location.state.autoFormGenre,

            government: data.government || '',
            tel: data.tel || '',
            fax: data.fax || '',
            autoFormNo: data.autoFormNo || '',
            subject: data.subject || '',
            inform: data.inform || '',
            postScript: data.postScript || '',
            prefixInform: prefixInform.value || '',
            command: data.command || '',
            urgencyLevel: data.urgencyLevel || '',
            governmentFt: data.governmentFt || '',
            telFt: data.telFt || '',
            faxFt: data.faxFt || '',
            emailFt: data.emailFt || '',
            content: content || '',
            departmentList: departmentList,
            listType: listType,
            referenceList: referenceList,
            attachList: attachList,

            f2ReferenceList: f2ReferenceList,
            f2AttachList: f2AttachList,
            f2Government: data.f2Government || '',
            f2Tel: data.f2Tel || '',
            f2Fax: data.f2Fax || '',
            f2AutoFormNo: data.f2AutoFormNo || '',
            f2Subject: data.f2Subject || '',
            f2Inform: data.f2Inform || '',
            f2PostScript: data.f2PostScript || '',
            f2PrefixInform: f2PrefixInform.value || '',
            f2Command: data.f2Command || '',
            f2GovernmentFt: data.f2GovernmentFt || '',
            f2TelFt: data.f2TelFt || '',
            f2FaxFt: data.f2FaxFt || '',
            f2EmailFt: data.f2EmailFt || '',
            f2Content: content2 || '',
            f2DepartmentList: f2DepartmentList,
            f2ListType: f2ListType,

            createInstead: data.createInstead,

            documentBy: documentState.documentBy,
        }
        // if (autoFormDate != null) {
        //     bodyData.autoFormDate = moment(autoFormDate).format('YYYY-MM-DD');
        // }

        if (autoFormDueDate != null) {
            bodyData.autoFormDueDate = moment(autoFormDueDate).format('YYYY-MM-DD');
        }

        // if (f2AutoFormDate != null) {
        //     bodyData.f2AutoFormDate = moment(f2AutoFormDate).format('YYYY-MM-DD');
        // }

        if (f2AutoFormDueDate != null) {
            bodyData.f2AutoFormDueDate = moment(f2AutoFormDueDate).format('YYYY-MM-DD');
        }

        let params = new FormData();
        params.append('data', JSON.stringify(bodyData));
        fileList.forEach((file) => {
            params.append('files', file);
        });
        mainApi.post('/api/form-auto/save', params).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                // text: 'เลขที่เอกสาร : ' + res.data.autoFormNo,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            }).then((result) => {
                if (
                    result.dismiss === Swal.DismissReason.timer ||
                    result.value === true
                ) {
                    history.push('/form-auto-box');
                }
            });
        });
    }

    const handleSubmitError = (errors) => {
        let firstKey = Object.keys(errors)[0];
        if (firstKey.indexOf('f2') > -1) {
            tab2Ref.current.click();
        } else {
            tab1Ref.current.click();
        }
    }

    const submitOrg = () => {
        let dataDepartment = getSentList();
        let departmentIdList = dataDepartment.map((n) => n.split(":")[0]);
        let departmentNameList = dataDepartment.map((n) => n.split(":")[1]);
        let dataRadio = getSelectRadio();

        if (parseInt(dataRadio.listType) === 1) {
            // setValue('inform', "แนบท้ายรายชื่อผู้อำนวยการในสังกัดกรมควบคุมโรค");
            setListType(1);
        } else if (parseInt(dataRadio.listType) === 2) {
            // setValue('inform', "แนบท้ายรายชื่อหน่วยงานสังกัดกรมควบคุมโรค");
            setListType(2);
        } else {
            setListType(null);
        }
        setDepartmentList(departmentIdList);
        setDepartmentNameList(departmentNameList);
    }

    const submitOrg2 = () => {
        let dataDepartment = getSentList2();
        let departmentIdList = dataDepartment.map((n) => n.split(":")[0]);
        let departmentNameList = dataDepartment.map((n) => n.split(":")[1]);
        let dataRadio = getSelectRadio2();

        if (parseInt(dataRadio.listType) === 1) {
            // setValue('f2Inform', "แนบท้ายรายชื่อผู้อำนวยการในสังกัดกรมควบคุมโรค");
            setF2ListType(1);
        } else if (parseInt(dataRadio.listType) === 2) {
            // setValue('f2Inform', "แนบท้ายรายชื่อหน่วยงานสังกัดกรมควบคุมโรค");
            setF2ListType(2);
        } else {
            setF2ListType(null);
        }
        setF2DepartmentList(departmentIdList);
        setF2DepartmentNameList(departmentNameList);
    }

    const getSentList = () => {
        return orgEmpListAutoRef.current.getAllSelectList();
    };

    const getSentList2 = () => {
        return orgEmpListAutoRef2.current.getAllSelectList();
    };

    const getSelectRadio = () => {
        return orgEmpListAutoRef.current.getSelectRadio();
    }

    const getSelectRadio2 = () => {
        return orgEmpListAutoRef2.current.getSelectRadio();
    }

    const handleAppendFields = (fieldName, object) => {
        if (fieldName === 'referenceList') {
            setReferenceList([...referenceList, object]);
        }
        if (fieldName === 'f2ReferenceList') {
            setF2ReferenceList([...f2ReferenceList, object]);
        }
        if (fieldName === 'attachList') {
            setAttachList([...attachList, object]);
        }
        if (fieldName === 'f2AttachList') {
            setF2AttachList([...f2AttachList, object]);
        }
    }

    const handleRemoveFields = (fieldName, index) => {
        if (fieldName === 'referenceList') {
            let dataList = [...referenceList];
            dataList.splice(index, 1);
            setReferenceList(dataList);
        }
        if (fieldName === 'f2ReferenceList') {
            let dataList = [...f2ReferenceList];
            dataList.splice(index, 1);
            setF2ReferenceList(dataList);
        }
        if (fieldName === 'attachList') {
            let dataList = [...attachList];
            dataList.splice(index, 1);
            setAttachList(dataList);
        }
        if (fieldName === 'f2AttachList') {
            let dataList = [...f2AttachList];
            dataList.splice(index, 1);
            setF2AttachList(dataList);
        }
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        {autoFormName}
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ทะเบียนแม่แบบ
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./form-auto-menu-list" className="text-breadcrumb">
                                                แม่แบบ
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            {autoFormName}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <Form className="form-auto" onSubmit={handleSubmit(handleSaveDraft, handleSubmitError)}>
                            <Tab.Container defaultActiveKey="tab1">
                                <Nav variant="pills" defaultActiveKey="tab1" className="justify-content-center mb-3 ">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1" ref={tab1Ref}>
                                            เอกสารเสนอผู้บังคับบัญชา
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2" ref={tab2Ref} disabled={!openTab2}>
                                            เอกสารเสนอผู้บังคับบัญชาสูงขึ้น 1 ระดับ
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tab1">
                                        <Card>
                                            <Card.Header className="form-auto-header">หัวหนังสือราชการ</Card.Header>
                                            <Card.Body>
                                                {location.state && location.state.formId !== 17 && (
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ส่วนราชการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="government" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        {location.state && location.state.autoFormGenre !== "OUT" && (
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">โทร</span>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control type="text" name="tel" ref={register}/>
                                                                </Col>
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">โทรสาร</span>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control type="text" name="fax" ref={register}/>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        {/*<Row className="mb-3">*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                        {/*        <span className="text-danger"> *</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <DateInput*/}
                                                        {/*            name="autoFormDate"*/}
                                                        {/*            value={autoFormDate}*/}
                                                        {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                                        {/*            inputRef={register({required: true})}*/}
                                                        {/*        />*/}
                                                        {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">เรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="subject" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <CreatableSelect
                                                                    isClearable
                                                                    className="selectInform"
                                                                    options={informList}
                                                                    onChange={(selected) => {
                                                                        setPrefixInform(selected)
                                                                        if (selected) {
                                                                            setValue("postScript", selected.postScript);
                                                                        }
                                                                        if (selected.value === "ทูล (ชั้นหม่อมเจ้า)" || selected.value === "ทูล (ชั้นพระวรวงศ์เธอ)") {
                                                                            setPrefixInform({value: "ทูล", label: selected.value})
                                                                        }
                                                                    }}
                                                                    value={prefixInform}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="inform" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำลงท้าย</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="postScript" ref={register} defaultValue={"ขอแสดงความนับถือ"}/>
                                                            </Col>
                                                        </Row>
                                                        {showSelectDepartment && (
                                                            <>
                                                                <Row className="mb-3">
                                                                    <Col sm={12} md={2}>

                                                                    </Col>
                                                                    <Col>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            className="btn-color-submit-small mr-2"
                                                                            onClick={() => {
                                                                                orgEmpListAutoRef.current.setRadioListType(listType);
                                                                                orgEmpListAutoRef.current.show()
                                                                            }
                                                                            }
                                                                        >
                                                                            <i className="fas fa-paper-plane pr-2"></i>เลือกหน่วยงาน
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={12} md={2}>

                                                                    </Col>
                                                                    <Col>
                                                                        <div>
                                                                            {departmentNameList.map((data, index) => {
                                                                                return <p className='mb-0' key={data}>- {data}</p>
                                                                            })}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {location.state && location.state.formId === 17 && (
                                                    <>
                                                        {/*<Row className="mb-3">*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                        {/*        <span className="text-danger"> *</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <DateInput*/}
                                                        {/*            name="autoFormDate"*/}
                                                        {/*            value={autoFormDate}*/}
                                                        {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                                        {/*            inputRef={register({required: true})}*/}
                                                        {/*        />*/}
                                                        {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">เรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="subject" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ถึง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="inform" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                                {location.state && location.state.autoFormGenre === "OUT" && (
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">อ้างถึง</span>
                                                                <Button
                                                                    type="button"
                                                                    className="btn-color-submit-small ml-2 float-right"
                                                                    // onClick={() => refAppend({referenceId: 0, detail: ""})}
                                                                    onClick={() => handleAppendFields('referenceList', {referenceId: 0, detail: ""})}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col sm={12} md={10}>
                                                                {referenceList.length === 0 && (
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                )}
                                                                {referenceList.map((item, index) => (
                                                                    <div key={index}>
                                                                        <Row className="mb-1">
                                                                            <Col>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    defaultValue={item.referenceId}
                                                                                    hidden
                                                                                />
                                                                                <InputGroup className="mb-3">
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.detail}
                                                                                        onChange={(e) => {
                                                                                            let dataList = referenceList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                detail: e.target.value
                                                                                            } : item);
                                                                                            setReferenceList(dataList);
                                                                                        }}
                                                                                    />
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn-color-delete-small  ml-2"
                                                                                        onClick={() => handleRemoveFields('referenceList', index)}
                                                                                    >
                                                                                        ลบ
                                                                                    </Button>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">สิ่งที่ส่งมาด้วย</span>
                                                                <Button
                                                                    type="button"
                                                                    className="btn-color-submit-small ml-2 float-right"
                                                                    onClick={() => handleAppendFields('attachList', {attachId: 0, detail: "", qty: "", unit: "แผ่น"})}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col sm={12} md={10}>
                                                                {attachList.length === 0 && (
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                )}
                                                                {attachList.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <Row className="mb-3">
                                                                                <Col sm={12} md={7}>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        defaultValue={item.attachId}
                                                                                        hidden
                                                                                    />
                                                                                    <InputGroup>
                                                                                        <InputGroup.Prepend>
                                                                                            <InputGroup.Text>{index + 1}.</InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={item.detail}
                                                                                            onChange={(e) => {
                                                                                                let detailDataList = attachList.map((item, i) => i === index ? {
                                                                                                    ...item,
                                                                                                    detail: e.target.value
                                                                                                } : item);
                                                                                                setAttachList(detailDataList);
                                                                                            }}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </Col>
                                                                                <Col sm={12} md={2}>
                                                                                    <InputGroup>
                                                                                        <InputGroup.Prepend>
                                                                                            <InputGroup.Text>จำนวน</InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={item.qty}
                                                                                            onChange={(e) => {
                                                                                                let qtyDataList = attachList.map((item, i) => i === index ? {
                                                                                                    ...item,
                                                                                                    qty: e.target.value
                                                                                                } : item);
                                                                                                setAttachList(qtyDataList);
                                                                                            }}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </Col>
                                                                                <Col sm={12} md={2}>
                                                                                    <select
                                                                                        className="form-control shadow-none"
                                                                                        value={item.unit}
                                                                                        onChange={(e) => {
                                                                                            let unitDataList = attachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                unit: e.target.value
                                                                                            } : item);
                                                                                            setAttachList(unitDataList);
                                                                                        }}
                                                                                    >
                                                                                        {unit.map((item) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={item.id}
                                                                                                    value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </Col>
                                                                                <Col sm={12} md={1}>
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn-color-delete-small ml-1 float-right"
                                                                                        onClick={() => handleRemoveFields('attachList', index)}
                                                                                    >
                                                                                        ลบ
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Card.Body>

                                            <Card.Footer className="form-auto-header">ส่วนข้อความ
                                                <Button
                                                    type="button"
                                                    className="btn-color-submit-small float-right"
                                                    onClick={() => {
                                                        PdfProcess('1');
                                                    }}
                                                >
                                                    <i className="far fa-file-pdf pr-2"></i>ตัวอย่างเอกสาร
                                                </Button>
                                            </Card.Footer>
                                            <Card.Body>
                                                <FroalaEditor model={content} onModelChange={setContent}/>
                                            </Card.Body>

                                            {((location.state && location.state.autoFormGenre !== "OUT") && (location.state && location.state.formId !== 17)) && (
                                                <>
                                                    <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <select className="form-control shadow-none" name="urgencyLevel" ref={register}>
                                                                    {urgencyLevel.map((item) => {
                                                                        return (
                                                                            <option
                                                                                key={item.id}
                                                                                value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำสั่งการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control as="textarea" rows={3} name="command" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <DateInput
                                                                    name="autoFormDueDate"
                                                                    value={autoFormDueDate}
                                                                    onChange={(date) => setAutoFormDueDate(date)}
                                                                    inputRef={register}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            )}

                                            {((location.state && location.state.autoFormGenre === "OUT") || (location.state && location.state.formId === 17)) && (
                                                <>
                                                    <Card.Footer className="form-auto-header">ส่วนท้ายหนังสือราชการ</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ส่วนราชการเจ้าของเรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="governmentFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทร</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Control type="text" name="telFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทรสาร</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Control type="text" name="faxFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        {location.state && location.state.formId !== 4 && (
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">ไปรษณีย์อิเล็กทรอนิกส์</span><br/>
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                </Col>
                                                                <Col sm={12} md={4}>
                                                                    <Form.Control type="text" name="emailFt" ref={register}/>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Card.Body>

                                                    <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <select className="form-control shadow-none" name="urgencyLevel" ref={register}>
                                                                    {urgencyLevel.map((item) => {
                                                                        return (
                                                                            <option
                                                                                key={item.id}
                                                                                value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำสั่งการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control as="textarea" rows={3} name="command" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <DateInput
                                                                    name="autoFormDueDate"
                                                                    value={autoFormDueDate}
                                                                    onChange={(date) => setAutoFormDueDate(date)}
                                                                    inputRef={register}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            )}

                                            <Card.Footer className="form-auto-header">ส่วนเพิ่มเติม</Card.Footer>
                                            <Card.Body>
                                                <Row className="mb-3">
                                                    <Col sm={12} md={3}>
                                                        <span className="text-color-form align-middle">สร้างแบบฟอร์มผ่านหัวหน้ากลุ่ม</span>
                                                    </Col>
                                                    <Col>
                                                        <Form.Check
                                                            type={"radio"}
                                                            label={"ไม่ต้องการสร้างแบบฟอร์มผ่านหัวหน้ากลุ่ม"}
                                                            name={"createInstead"}
                                                            value={0}
                                                            ref={register}
                                                            defaultChecked
                                                            onChange={() => setOpenTab2(false)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={12} md={3}></Col>
                                                    <Col>
                                                        <Form.Check
                                                            type={"radio"}
                                                            label={"ต้องการสร้างแบบฟอร์มผ่านหัวหน้ากลุ่ม (กรุณากรอกข้อมูล)"}
                                                            name={"createInstead"}
                                                            value={1}
                                                            ref={register}
                                                            onChange={() => setOpenTab2(true)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>

                                            <Card.Footer className="form-auto-header">แนบไฟล์</Card.Footer>
                                            <Card.Body>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <UploadFiles fileList={fileList} setFileList={setFileList}/>
                                                    </Col>
                                                </Row>
                                            </Card.Body>

                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <Card>
                                            <Card.Header className="form-auto-header ">หัวหนังสือราชการ
                                                <Button
                                                    type="button"
                                                    className="btn-color-submit-small float-right"
                                                    onClick={() => copyDataToFormAuto2()}
                                                >
                                                    <i className="far fa-copy pr-2"></i>คัดลอกข้อมูลเอกสารเสนอผู้บังคับบัญชา
                                                </Button>
                                            </Card.Header>
                                            <Card.Body>
                                                {location.state && location.state.formId !== 17 && (
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ส่วนราชการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Government" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        {location.state && location.state.autoFormGenre !== "OUT" && (
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">โทร</span>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control type="text" name="f2Tel" ref={register}/>
                                                                </Col>
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">โทรสาร</span>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control type="text" name="f2Fax" ref={register}/>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        {/*<Row className="mb-3">*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <Form.Control type="text" name="f2AutoFormNo" ref={register} disabled/>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                        {/*        <span className="text-danger"> *</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <DateInput*/}
                                                        {/*            name="f2AutoFormDate"*/}
                                                        {/*            value={f2AutoFormDate}*/}
                                                        {/*            onChange={(date) => setF2AutoFormDate(date)}*/}
                                                        {/*            inputRef={register({required: openTab2})}*/}
                                                        {/*        />*/}
                                                        {/*        {errors.f2AutoFormDate && !f2AutoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">เรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Subject" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <CreatableSelect
                                                                    isClearable
                                                                    className="selectInform"
                                                                    options={informList}
                                                                    onChange={(selected) => {
                                                                        setF2PrefixInform(selected)
                                                                        if (selected) {
                                                                            setValue("f2PostScript", selected.postScript);
                                                                        }
                                                                        if (selected.value === "ทูล (ชั้นหม่อมเจ้า)" || selected.value === "ทูล (ชั้นพระวรวงศ์เธอ)") {
                                                                            setF2PrefixInform({value: "ทูล", label: selected.value})
                                                                        }
                                                                    }}
                                                                    value={f2PrefixInform}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Inform" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำลงท้าย</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2PostScript" ref={register} defaultValue={"ขอแสดงความนับถือ"}/>
                                                            </Col>
                                                        </Row>
                                                        {showSelectDepartment && (
                                                            <>
                                                                <Row className="mb-3">
                                                                    <Col sm={12} md={2}>

                                                                    </Col>
                                                                    <Col>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            className="btn-color-submit-small mr-2"
                                                                            onClick={() => orgEmpListAutoRef2.current.show()}
                                                                        >
                                                                            <i className="fas fa-paper-plane pr-2"></i>เลือกหน่วยงาน
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={12} md={2}>

                                                                    </Col>
                                                                    <Col>
                                                                        <div>
                                                                            {f2DepartmentNameList.map((data, index) => {
                                                                                return <p className='mb-0' key={data}>- {data}</p>
                                                                            })}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {location.state && location.state.formId === 17 && (
                                                    <>
                                                        {/*<Row className="mb-3">*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <Form.Control type="text" name="f2AutoFormNo" ref={register} disabled/>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={2}>*/}
                                                        {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                        {/*        <span className="text-danger"> *</span>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col sm={12} md={4}>*/}
                                                        {/*        <DateInput*/}
                                                        {/*            name="f2AutoFormDate"*/}
                                                        {/*            value={f2AutoFormDate}*/}
                                                        {/*            onChange={(date) => setF2AutoFormDate(date)}*/}
                                                        {/*            inputRef={register({required: openTab2})}*/}
                                                        {/*        />*/}
                                                        {/*        {errors.f2AutoFormDate && !f2AutoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                        {/*    </Col>*/}
                                                        {/*</Row>*/}
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">เรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Subject" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ถึง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Inform" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                                {location.state && location.state.autoFormGenre === "OUT" && (
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">อ้างถึง</span>
                                                                <Button
                                                                    type="button"
                                                                    className="btn-color-submit-small ml-2 float-right"
                                                                    onClick={() => handleAppendFields('f2ReferenceList', {referenceId: 0, detail: ""})}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col sm={12} md={10}>
                                                                {f2ReferenceList.length === 0 && (
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                )}
                                                                {f2ReferenceList.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <Row className="mb-1">
                                                                                <Col>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        defaultValue={item.referenceId}
                                                                                        hidden
                                                                                    />
                                                                                    <InputGroup className="mb-3">
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={item.detail}
                                                                                            onChange={(e) => {
                                                                                                let f2ReferenceDataList = f2ReferenceList.map((item, i) => i === index ? {
                                                                                                    ...item,
                                                                                                    detail: e.target.value
                                                                                                } : item);
                                                                                                setF2ReferenceList(f2ReferenceDataList);
                                                                                            }}
                                                                                        />
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn-color-delete-small  ml-2"
                                                                                            onClick={() => handleRemoveFields('f2ReferenceList', index)}
                                                                                        >
                                                                                            ลบ
                                                                                        </Button>
                                                                                    </InputGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">สิ่งที่ส่งมาด้วย</span>
                                                                <Button
                                                                    type="button"
                                                                    className="btn-color-submit-small ml-2 float-right"
                                                                    onClick={() => handleAppendFields('f2AttachList', {attachId: 0, detail: "", qty: "", unit: "แผ่น"})}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col sm={12} md={10}>
                                                                {f2AttachList.length === 0 && (
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                )}
                                                                {f2AttachList.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <Row className="mb-3">
                                                                                <Col sm={12} md={7}>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        defaultValue={item.attachId}
                                                                                        hidden
                                                                                    />
                                                                                    <InputGroup>
                                                                                        <InputGroup.Prepend>
                                                                                            <InputGroup.Text>{index + 1}.</InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={item.detail}
                                                                                            onChange={(e) => {
                                                                                                let F2DetailDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                                    ...item,
                                                                                                    detail: e.target.value
                                                                                                } : item);
                                                                                                setF2AttachList(F2DetailDataList);
                                                                                            }}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </Col>
                                                                                <Col sm={12} md={2}>
                                                                                    <InputGroup>
                                                                                        <InputGroup.Prepend>
                                                                                            <InputGroup.Text>จำนวน</InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={item.qty}
                                                                                            onChange={(e) => {
                                                                                                let F2QtyDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                                    ...item,
                                                                                                    qty: e.target.value
                                                                                                } : item);
                                                                                                setF2AttachList(F2QtyDataList);
                                                                                            }}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </Col>
                                                                                <Col sm={12} md={2}>
                                                                                    <select
                                                                                        className="form-control shadow-none"
                                                                                        value={item.unit}
                                                                                        onChange={(e) => {
                                                                                            let F2UnitDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                unit: e.target.value
                                                                                            } : item);
                                                                                            setF2AttachList(F2UnitDataList);
                                                                                        }}
                                                                                    >
                                                                                        {unit.map((item) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={item.id}
                                                                                                    value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </Col>
                                                                                <Col sm={12} md={1}>
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn-color-delete-small ml-2 float-right"
                                                                                        onClick={() => handleRemoveFields('f2AttachList', index)}
                                                                                    >
                                                                                        ลบ
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Card.Body>

                                            <Card.Footer className="form-auto-header">ส่วนข้อความ
                                                <Button
                                                    type="button"
                                                    className="btn-color-submit-small float-right"
                                                    onClick={() => {
                                                        PdfProcess('2');
                                                    }}
                                                >
                                                    <i className="far fa-file-pdf pr-2"></i>ตัวอย่างเอกสาร
                                                </Button>
                                            </Card.Footer>
                                            <Card.Body>
                                                <FroalaEditor model={content2} onModelChange={setContent2}/>
                                            </Card.Body>

                                            {((location.state && location.state.autoFormGenre !== "OUT") && (location.state && location.state.formId !== 17)) && (
                                                <>
                                                    <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำสั่งการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control as="textarea" rows={3} name="f2Command" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <DateInput
                                                                    name="f2AutoFormDueDate"
                                                                    value={f2AutoFormDueDate}
                                                                    onChange={(date) => setF2AutoFormDueDate(date)}
                                                                    inputRef={register}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            )}

                                            {((location.state && location.state.autoFormGenre === "OUT") || (location.state && location.state.formId === 17)) && (
                                                <>
                                                    <Card.Footer className="form-auto-header">ส่วนท้ายหนังสือราชการ</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">ส่วนราชการเจ้าของเรื่อง</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2GovernmentFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทร</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Control type="text" name="f2TelFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทรสาร</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <Form.Control type="text" name="f2FaxFt" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        {location.state && location.state.formId !== 4 && (
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>
                                                                    <span className="text-color-form align-middle">ไปรษณีย์อิเล็กทรอนิกส์</span><br/>
                                                                    <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                                </Col>
                                                                <Col sm={12} md={4}>
                                                                    <Form.Control type="text" name="f2EmailFt" ref={register}/>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Card.Body>

                                                    <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                    <Card.Body>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">คำสั่งการ</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control as="textarea" rows={3} name="f2Command" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                            </Col>
                                                            <Col sm={12} md={4}>
                                                                <DateInput
                                                                    name="f2AutoFormDueDate"
                                                                    value={f2AutoFormDueDate}
                                                                    onChange={(date) => setF2AutoFormDueDate(date)}
                                                                    inputRef={register}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            )}
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div>
                                <Button
                                    type="submit"
                                    className="btn-color-add-small mr-2"
                                >
                                    <i className="far fa-save pr-2"></i>บันทึกร่าง
                                </Button>
                                <Button
                                    type="button"
                                    className="btn-color-delete-small"
                                    onClick={() => history.goBack()}
                                >
                                    <i className="fas fa-ban pr-2"></i>ยกเลิก
                                </Button>
                            </div>
                        </Form>

                        <div className="p-4"/>
                    </Container>
                </section>
            </div>
            <FormAutoPreviewModal
                ref={handlePdfPreviewRef}
            />
            <OrgEmpListAuto onAccept={submitOrg} ref={orgEmpListAutoRef}/>
            <OrgEmpListAuto onAccept={submitOrg2} ref={orgEmpListAutoRef2}/>
        </div>
    );
}
;
