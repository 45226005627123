import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { getDateTimeFormat } from '../util/Date';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #6c757d;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const UploadFiles = ({ fileList, setFileList }) => {
  const fileAccept =
    '.pdf, .txt, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .tiff, .tif, .zip, .rar, .7z';
  const fileSize = 10;
  const [messageError, setMessageError] = useState([]);
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: fileAccept,
    maxSize: fileSize * 1024 * 1024,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => handleAccepted(files),
    onDropRejected: (fileRejections) => handleRejected(fileRejections),
    onDrop: () => handleOnDrop(),
  });

  const handleAccepted = (files) => {
    let handleFiles = fileList.filter((file) => {
      let newFile = files.filter((newFileLoop) => {
        return file.path === newFileLoop.path;
      });
      if (newFile.length > 0) {
        return false;
      }
      return true;
    });
    setFileList(handleFiles.concat(files));
  };

  const handleRejected = (fileRejections) => {
    setMessageError(fileRejections);
  };

  const deleteFile = (file) => {
    setFileList(fileList.filter((filterFile) => filterFile !== file));
  };

  const handleOnDrop = () => {
    setMessageError([]);
  };

  const fileDisplay = fileList.map((file) => (
    <li key={file.path}>
      <b>{file.path} ({getDateTimeFormat(new Date())})</b> - {file.size} bytes
      <i
        className="fas fa-trash-alt ml-2 text-secondary ml-1 "
        style={{ cursor: 'pointer' }}
        onClick={() => deleteFile(file)}
      ></i>
    </li>
  ));

  const errorDisplay = messageError.map(({ errors, file }) => {
    let result = (
      <li key={file.name}>
        <b>{file.name}</b> - {errors[0].message}
      </li>
    );

    if (errors[0].code === 'file-too-large') {
      result = (
        <li key={file.name}>
          <b>{file.name}</b> - ไฟล์มีขนาดมากกว่า {fileSize} MB
        </li>
      );
    }

    if (errors[0].code === 'file-invalid-type') {
      result = (
        <li key={file.name}>
          <b>{file.name}</b> - นามสกุลไฟล์ต้องเป็น {fileAccept}
        </li>
      );
    }

    return result;
  });

  return (
    <Container
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
    >
      <input {...getInputProps()} />

      <p>ลากและวางไฟล์ที่นี่</p>

      <Button
        variant="contained"
        className="btn-no-border btn-color-search"
        onClick={open}
      >
        <i className="fas fa-paperclip pr-2"></i>
        แนบไฟล์
      </Button>

      <div className="mt-4 text-warn" >
        <label>(รองรับไฟล์ขนาดไม่เกิน 10 MB)</label>
      </div>

      {errorDisplay.length > 0 && (
        <div className="text-color-form absmt-2 text-danger">
          <label>เกิดข้อผิดพลาด</label>
          <ul>{errorDisplay}</ul>
        </div>
      )}

      {fileDisplay.length > 0 && (
        <div className="text-color-form absmt-2">
          <label>ไฟล์แนบ</label>
          <ul>{fileDisplay}</ul>
        </div>
      )}
    </Container>
  );
};

export default UploadFiles;
