import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { mainApi } from '../../HttpClient';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';
import OrgEmpList from '../share/OrgEmpList';
import DateInput from '../share/DateInput';
import { getApiMasUrgencyLevel, getApiMasSecretLevel, getApiMasSectionLevel } from '../util/ApiForSelect';
import { getCurrentDocRunning } from '../util/DocRegister';
import { getDateTimeFormat } from '../util/Date';

const SecretDocInboxInternal = () => {
    let history = useHistory();
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);
    const { register, errors, handleSubmit, setError, getValues, setValue, reset } = useForm();
    const [documentDate, setDocumentDate] = useState(new Date());
    const [documentDuedate, setDocumentDuedate] = useState(new Date());
    const [outsideOrg, setOutsideOrg] = useState('');
    const orgEmpListRef = useRef();
    const [referSelections, setReferSelections] = useState([]);
    const [referOptions, setReferOptions] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(false);
    const [modalSentOutShow, setModalSentOutShow] = useState(false);
    const handleModalSentOutClose = () => setModalSentOutShow(false);
    // const handleModalSentOutShow = () => setModalSentOutShow(true);
    const [listUrgencyLevel, setListUrgencyLevel] = useState([]);
    const [listSecretLevel, setListSecretLevel] = useState([]);
    const [listSectionLevel, setListSectionLevel] = useState([]); //new state
    const [docRunningNo, setDocRunningNo] = useState(1);
    const [isCheckedDueDate, setIsCheckedDueDate] = useState(false);
    const [isReceive, setIsReceive] = useState(false);
    const [isReceiveAndSend, setReceiveAndSend] = useState(false);

    const [selectUrgencyLevel, setSelectUrgencyLevel] = useState('');
    const [selectSectionLevel, setSelectSctionLevel] = useState(); //new state 
    const [detailSectionLevel, setDetailSectionLevel] = useState('');
    const [inputSub, setInputSub] = useState('');
    const [isSub, setIsSub] = useState();
    const [inputSection, setInputSction] = useState();
    const [isSection, setIsSection] = useState();
    const [specialReceiver, setSpecialReceiver] = useState();

    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    useEffect(() => {

        const changeSub = () => {
            if (selectSectionLevel === '1') {
                setInputSction(75)
                setIsSection(false)
            } else {
                setInputSction(20)
                setIsSection(true)
            }
            setDetailSectionLevel(listSectionLevel.filter((item) => item.section_level_id === parseInt(selectSectionLevel)));
        }
        changeSub();
        console.log("selectSectionLevel", selectSectionLevel);
        console.log("detailSectionLevel", detailSectionLevel);
        console.log("listSectionLevel", listSectionLevel);
    }, [selectSectionLevel]);

    useEffect(() => {
        const changeSub = () => {
            if (selectUrgencyLevel <= 1 || selectUrgencyLevel === '') {
                setInputSub('')
                // console.log('ค่าว่าง')
                setIsSub(false)
            } else {
                if (selectUrgencyLevel === '2') {
                    setInputSub('หนังสือลับ')
                } else if (selectUrgencyLevel === '3') {
                    setInputSub('หนังสือลับมาก')
                }
                // console.log('อื่นๆ')
                setIsSub(true)
            }
        }
        changeSub();
    }, [selectUrgencyLevel]);

    useEffect(() => {
        const rsUrgen = getApiMasUrgencyLevel();
        rsUrgen.then((res) => {
            setListUrgencyLevel(res.data);
        });

        const rsSecret = getApiMasSecretLevel();
        rsSecret.then((res) => {

            setListSecretLevel(res.data.filter((item) => item.secret_level_id !== 1));
            setInputSub('หนังสือลับ');
            setIsSub(true);
        });

        const rsRection = getApiMasSectionLevel();

        rsRection.then((res) => {
            console.log("rsRection: " + res.data);

            setListSectionLevel(res.data.filter((item) => item.section_level_id));
            setDetailSectionLevel(listSectionLevel.filter((item) => item.section_level_id === 1));
            setSelectSctionLevel("0")
            setInputSction('');
            setInputSction();
        });
    }, []);

    useEffect(() => {
        if (documentState.documentBy && documentState.documentBy.id !== null) {
            setIsLoadingDocRunningNo(true);
            const docType = 'HR';
            const documentGenre = 'IN';
            getCurrentDocRunning(docType, documentState.documentBy, documentGenre).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [documentState.documentBy]);

    useEffect(() => {
        if (documentState.documentBy && documentState.documentBy.id !== null) {
            const param = {
                documentBy: documentState.documentBy,
            }
            mainApi.post('/api/book/get-special-receiver', param).then((res) => {
                setSpecialReceiver(res.data);
            });
        }
    }, [documentState.documentBy]);

    const handleSave = (data, pdfSignList) => {
        setIsReceive(true);
        save(data, pdfSignList);
    };

    const handleSendInSave = (data) => {
        setReceiveAndSend(true);
        save(data, []);
    };

    const handleSendOutSave = (data) => {
        save(data, []);
        handleModalSentOutClose();
    };

    function previewFile() {
        const fileUrl = config.get('serectManual');

        window.open(fileUrl, '_blank');
    }



    const save = (data, pdfSignList) => {
        //selectSectionLevel insert section_level_id 
        let receiverId = documentState.documentBy.id;
        let receiver = documentState.documentBy.name;
        let destructionYears = inputSection;
        if (specialReceiver.receiverId !== null && specialReceiver.receiver !== null) {
            receiverId = specialReceiver.receiverId;
            receiver = specialReceiver.receiver;
        }

        let bodyData = {
            documentId: '0',
            documentType: 'HR',
            documentGenre: 'IN',
            registerNo: data.registerNo || '',
            documentDate: moment(documentDate).format('YYYY-MM-DD'),
            urgencyLevel: data.urgencyLevel,
            secretLevel: data.secretLevel,
            subject: data.subject || inputSub || '',
            senderId: '',
            sender: data.owner || '',
            receiverId: receiverId,
            receiver: receiver,
            edocReceiver: data.receiver,
            owner: data.owner || '',
            ownerTelephone: data.ownerTelephone || '',
            remark: data.remark || '',
            command: data.command || '',
            status: 2,
            insideOrg: [receiverId + ':' + receiver],
            destructionYear: destructionYears,
            sectionLevelId: data.sectionLevel === "0" ? null : data.sectionLevel,
            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentBy: documentState.documentBy,
        }

        if (referSelections.length > 0) {
            bodyData = {
                ...bodyData,
                refer: referSelections[0].documentId,
            }
        }

        if (isCheckedDueDate === true) {
            bodyData = {
                ...bodyData,
                documentDuedate: moment(documentDuedate).format('YYYY-MM-DD'),
            }
        }

        // เก็บค่าหน่วยงาน สำหรับรับแล้วส่งต่อ
        bodyData = {
            ...bodyData,
            recAndSendOrg: orgEmpListRef.current.getAllSelectList(),
        }

        let params = new FormData();
        console.log('data', JSON.stringify(bodyData));
        params.append('data', JSON.stringify(bodyData));

        mainApi.post('/api/book/save/v2', params).then((res) => {
            reset(res.data.book);
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: `รับหนังสือ ${data.registerNo} สำเร็จ`,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            }).then((result) => {
                if (
                    result.dismiss === Swal.DismissReason.timer ||
                    result.value === true
                ) {
                    history.push('/secret-doc-inbox');
                }
            });
        }).finally(() => {
            setIsReceive(false);
            setReceiveAndSend(false);
        });
    };

    const handleReferSearch = (query) => {
        setIsReferLoading(true);

        let params = {
            registerNo: query,
        };

        mainApi.post('/api/v1/documents/search/register_no', params).then((res) => {
            setReferOptions(res.data);
        }).finally(() => {
            setIsReferLoading(false);
        });
    };

    const handleIsRegisterNoExists = async () => {
        let registerNo = getValues('registerNo');
        if (registerNo && documentState.documentBy.id) {
            let response = await mainApi.post(
                '/api/v2/documents/exists/register_no?registerNo=' + registerNo + '&documentById=' + documentState.documentBy.id
            );
            if (response.data.length > 0) {
                setError('registerNo', {
                    type: 'manual',
                    message: `เลขที่หนังสือ ${registerNo} ซ้ำ`,
                });
                setError('registerNoDate', {
                    type: 'manual',
                    message: getDateTimeFormat(response.data[0].createdDate),
                });
                setValue('registerNo', '');
            } else {
                setError('registerNo', {
                    type: 'manual',
                    message: '',
                });
                setError('registerNoDate', {
                    type: 'manual',
                    message: '',
                });
            }
        }
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        หนังสือรับ (ลับ)
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./secret-doc-inbox" className="text-breadcrumb">
                                                ทะเบียนหนังสือรับ (ลับ)
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            หนังสือรับ (ลับ)
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ที่
                                                </span>
                                                <span className="text-danger"> *</span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="registerNo"
                                                    onBlur={() => {
                                                        handleIsRegisterNoExists();
                                                    }}
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message:
                                                                (errors.registerNo &&
                                                                    errors.registerNo.message) ||
                                                                'กรุณากรอกข้อมูล',
                                                        },
                                                    })}
                                                />
                                                {errors.registerNo && (
                                                    <div className="text-danger">
                                                        {errors.registerNo.message}
                                                    </div>
                                                )}
                                                <input
                                                    type="hidden"
                                                    name="registerNoDate"
                                                    ref={register}
                                                />
                                                {errors.registerNoDate && (
                                                    <div className="text-danger">
                                                        {errors.registerNoDate.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ลงวันที่
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <DateInput
                                                    value={documentDate}
                                                    onChange={(date) => setDocumentDate(date)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ชั้นความลับ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <select
                                                    className="form-control shadow-none"
                                                    name="secretLevel"
                                                    ref={register}
                                                    onChange={(e) => setSelectUrgencyLevel(e.target.value)}
                                                >
                                                    {listSecretLevel.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.secret_level_id}
                                                                value={item.secret_level_id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-12 col-md-2 pt-2 text-sm-left text-md-center">
                                                <span className="text-color-form align-middle">
                                                    ชั้นความเร็ว
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <select
                                                    className="form-control shadow-none"
                                                    name="urgencyLevel"
                                                    ref={register}
                                                >
                                                    {listUrgencyLevel.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.urgency_level_id}
                                                                value={item.urgency_level_id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    มาตรา
                                                </span>
                                                {/* <span className="text-danger"> *</span> */}
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <select
                                                    className="form-control shadow-none"
                                                    name="sectionLevel"
                                                    ref={register}
                                                    onChange={(e) => setSelectSctionLevel(e.target.value)}
                                                >
                                                    <option value="0" tag="">
                                                                ไม่ระบุ
                                                                </option>
                                                    {listSectionLevel.map((item) => {
                                                        return (
                                                            
                                                            <option
                                                                key={item.section_level_id}
                                                                value={item.section_level_id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-2 pt-2">
                                            <Button
                                                   className="btn-color-submit btn-block"
                                                   onClick={() => previewFile()}
                                                   disabled={selectSectionLevel === "0"}
                                                >
                                                    <i style={{ marginRight: '10px '}} className="fas fa-regular fa-eye" ></i>
                                                     รายละเอียดมาตรา
                                                    {isReceive && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    {/* มาตรา  */}
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                {detailSectionLevel && detailSectionLevel[0] && (
                                                    <span style={{ fontWeight: 'bold', color: 'black', fontSize: '1.1em' }}>
                                                        รายละเอียด : {detailSectionLevel[0].name || ''}
                                                    </span>
                                                )}
                                                <br />
                                                {detailSectionLevel && detailSectionLevel[0] && detailSectionLevel[0].detail || '' }
                                            </div>

                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เรื่อง
                                                </span>
                                                <span className="text-danger"> *</span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="subject"
                                                    // readOnly={isSub}
                                                    value={inputSub}
                                                    onChange={e => setInputSub(e.target.value)}
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message: 'กรุณากรอกข้อมูล',
                                                        },
                                                        maxLength: {
                                                            value: 500,
                                                            message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                                                        },
                                                    })}
                                                />
                                                {errors.subject && (
                                                    <span className="text-danger">
                                                        {errors.subject.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เรียน
                                                </span>
                                                <span className="text-danger"> *</span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none d-none"
                                                    type="text"
                                                    name="receiverId"
                                                    ref={register}
                                                />
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="receiver"
                                                    ref={register({ required: true })}
                                                />
                                                {errors.receiver && (
                                                    <span className="text-danger">กรุณากรอกข้อมูล</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ (ถ้ามี)
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    id="basic-typeahead-single"
                                                    isLoading={isReferLoading}
                                                    labelKey="registerNo"
                                                    onChange={setReferSelections}
                                                    options={referOptions}
                                                    placeholder="กรุณาพิมพ์เลขที่หนังสือ..."
                                                    selected={referSelections}
                                                    minLength={2}
                                                    onSearch={handleReferSearch}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เจ้าของเรื่อง
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-6 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="owner"
                                                    ref={register}
                                                />
                                            </div>
                                            <div className="col-12 col-md-1 pt-2 text-md-center">
                                                <span className="text-color-form align-middle">
                                                    เบอร์ติดต่อ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="ownerTelephone"
                                                    ref={register({
                                                        maxLength: {
                                                            value: 50,
                                                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                                                        },
                                                    })}
                                                />
                                                {errors.ownerTelephone && (
                                                    <span className="text-danger">
                                                        {errors.ownerTelephone.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    คำสั่งการ (รายละเอียด)
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <textarea
                                                    className="form-control shadow-none"
                                                    rows="4"
                                                    name="command"
                                                    ref={register({ max: 255 })}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="remark"
                                                    ref={register({ max: 255 })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    วันที่กำหนดเอกสาร
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <div className="">
                                                    <Checkbox
                                                        // defaultChecked
                                                        checked={isCheckedDueDate}
                                                        color="secondary"
                                                        onChange={(event) => {
                                                            setIsCheckedDueDate(event.target.checked);
                                                        }}
                                                        name="checkboxDueDate"
                                                    />
                                                    <DateInput
                                                        value={documentDuedate}
                                                        onChange={(date) => setDocumentDuedate(date)}
                                                        disabled={!isCheckedDueDate}
                                                        readOnly={!isCheckedDueDate}
                                                        className="ml-50"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="row mt-3">*/}
                                        {/*    <div className="col-12 col-md-2 pt-2"></div>*/}
                                        {/*    <div className="col-12 col-md-10 pt-1">*/}
                                        {/*        <UploadFiles*/}
                                        {/*            fileList={fileList}*/}
                                        {/*            setFileList={setFileList}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 mt-3">
                                            <hr style={{ margin: '6px -10px 10px' }} />
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-sm-12 col-md-3 mt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="submit"*/}
                                            {/*        className="btn-color-submit btn-block"*/}
                                            {/*        disabled={isReceive}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            if(!isReceive) {*/}
                                            {/*                setSignReceiveAndSend(false);*/}
                                            {/*                handleSignature();*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-file-signature mr-2"></i>ลงนามรับหนังสือ*/}
                                            {/*        {isReceive && (*/}
                                            {/*            <div*/}
                                            {/*                className="spinner-border spinner-border-sm ml-2"*/}
                                            {/*                role="status"*/}
                                            {/*            >*/}
                                            {/*                <span className="sr-only">Loading...</span>*/}
                                            {/*            </div>*/}
                                            {/*        )}*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-sm-12 col-md-3 mt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="button"*/}
                                            {/*        className="btn-block btn-color-submit-small"*/}
                                            {/*        disabled={isReceiveAndSend}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            if(!isReceive) {*/}
                                            {/*                setSignReceiveAndSend(true);*/}
                                            {/*                handleSignature();*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-file-signature mr-2"></i>ลงนามรับและส่งต่อ*/}
                                            {/*        {isReceiveAndSend && (*/}
                                            {/*            <div*/}
                                            {/*                className="spinner-border spinner-border-sm ml-2"*/}
                                            {/*                role="status"*/}
                                            {/*            >*/}
                                            {/*                <span className="sr-only">Loading...</span>*/}
                                            {/*            </div>*/}
                                            {/*        )}*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            <div className="col-sm-12 col-md-3 mt-2">
                                                <Button
                                                    type="submit"
                                                    className="btn-color-submit btn-block"
                                                    disabled={isReceive}
                                                    onClick={!isReceive ? handleSubmit((data) => {
                                                        handleSave(data, []);
                                                    }) : null}
                                                >
                                                    <i className="fas fa-check-circle pr-2"></i>
                                                    รับหนังสือ
                                                    {isReceive && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                            <div className="col-sm-12 col-md-3 mt-2">
                                                <Button
                                                    type="button"
                                                    className="btn-block btn-color-submit-small"
                                                    disabled={isReceiveAndSend}
                                                    onClick={!isReceiveAndSend ? () => orgEmpListRef.current.show() : null}
                                                // onClick={() => orgEmpListRef.current.show()}
                                                >
                                                    <i className="fas fa-paper-plane pr-2"></i>
                                                    รับและส่งต่อ
                                                    {isReceiveAndSend && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                            {/*<div className="col-sm-12 col-md-3 mt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="reset"*/}
                                            {/*        className="btn-color-delete-small btn-block"*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-ban pr-2"></i>*/}
                                            {/*        ยกเลิก*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal show={modalSentOutShow} onHide={handleModalSentOutClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งออกหน่วยงานภายนอก
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">หน่วยงานภายนอก :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="10"
                                    style={{ width: '100%' }}
                                    value={outsideOrg}
                                    onChange={(e) => setOutsideOrg(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        onClick={handleSubmit(handleSendOutSave)}
                    >
                        <i className="fas fa-paper-plane pr-2"></i>
                        ส่งหนังสือ
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentOutClose}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <OrgEmpList onAccept={handleSubmit(handleSendInSave)} ref={orgEmpListRef} />
        </div>
    );
};

export default SecretDocInboxInternal;