import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { mainApi } from '../../HttpClient';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';

import { getDateThaiShortFormat } from '../util/Date';

const ReportControlSecertInfo = () => {
  const [keycloak] = useKeycloak();
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [dataTable, setDataTable] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const documentState = useSelector((state) => state.document);

  const { id: documentById } = documentState.documentBy;

  useEffect(() => {
    setIsDataLoading(true);
    const fecthData = async () => {
      let res = await mainApi.get(`/api/report/secertdocinfo/data/${selectYear}/${documentById}`);
      if (res.status === 200) {
        setDataTable(res.data);
      } else {
        setDataTable([]);
      }
      setIsDataLoading(false);
    };
    fecthData();
  }, [keycloak, selectYear]);

  const renderYear = () => {
    let thisYear = new Date().getFullYear();
    let endLoop = thisYear + 11;
    let options = [];
    for (let loopYear = thisYear - 4; loopYear < endLoop; loopYear++) {
      options.push(
        <option key={loopYear} value={loopYear}>
          {loopYear + 543} {thisYear === loopYear && '(ปีปัจจุบัน)'}
        </option>
      );
    }
    return options;
  };

  const handlePrint = (type) => {
    setIsExportFile(true);

    mainApi
      .get(`/api/report/secertdocinfo/${type}/${selectYear}/${documentById}`, {
        responseType: 'blob',
      })
      .then((res) => {
        if (type === 'pdf') {
          fileDownload(
            res.data,
            `รายงานทะเบียนควบคุมข้อมูลข่าวสารลับ.pdf`
          );
        } else {
          fileDownload(
            res.data,
            `รายงานทะเบียนควบคุมข้อมูลข่าวสารลับ.xlsx`
          );
        }
        setIsExportFile(false);
      });
  };

  const renderReport = () => {
    if (isDataLoading) {
      return (
        <tr>
          <td colSpan="5">
            <div
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
      );
    } else if (dataTable.length === 0) {
      return (
        <tr>
          <td colSpan="5">ไม่พบข้อมูล</td>
        </tr>
      );
    } else {
      return dataTable.map((item, index) => (
        <tr key={index}>
          <td>{item.registerNo}</td>
          <td>{item.sentNo}</td>
          <td>{item.receiveNo}</td>
          <td>{item.referName}</td>
          <td>{item.sender}</td>
          <td>
            <p>{item.refer != null ? 'มาตรา: ' + item.refer + '\n' : ''}</p>
            <p>{item.command != null && item.command  !== '' ? 'คำสั่งการ: ' + item.command + '\n' : ''}</p>
            <p>{item.status != null && item.status !== '' ? 'หมายเหตุ: ' + item.status + '\n' : ''}</p>
          </td>
        </tr>
      ));
    }
  };
  

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: '100vh' }}>
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    รายงานทะเบียนควบคุมข้อมูลข่าวสารลับ 
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb">
                      <Link to="./report" className="text-breadcrumb">
                        รายงาน
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      รายงานทะเบียนควบคุมข้อมูลข่าวสารลับ 
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <div className="row">
                    {/* <div className="col-12 col-md-4 pt-1">
                      <select
                        className="form-control shadow-none"
                        name="listYear"
                        onChange={(e) => setSelectYear(e.target.value)}
                        value={selectYear}
                      >
                        {renderYear()}
                      </select>
                    </div> */}
                    <div className="col-12 col-md-8 pt-1">
                      <Dropdown as={ButtonGroup} onSelect={!isExportFile ? handlePrint : null} >
                          <Dropdown.Toggle className="btn-no-border btn-color-add-small" disabled={isExportFile}>
                              <i className="fas fa-file pr-2"></i> พิมพ์
                              {isExportFile && (
                                <div
                                  className="spinner-border spinner-border-sm ml-2"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="super-colors">
                              <Dropdown.Item eventKey="excel"><i style={{color: 'green'}} className="fas fa-file-excel pr-2"></i> ส่งออกไฟล์ Excel</Dropdown.Item>
                              <Dropdown.Item eventKey="pdf"><i style={{color: 'red'}}  className="fas fa-file-pdf pr-2"></i> ส่งออกไฟล์ Pdf</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pt-2">
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                        className="text-center"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>ลำดับที่</th>
                            <th>เลขที่ส่ง</th>
                            <th>เลขที่รับ</th>
                            <th>ชั้นความลับ</th>
                            <th>หน่วยงานเจ้าของเรื่อง</th>
                            <th>การดำเนินการ</th>
                          </tr>
                        </thead>
                        <tbody>{renderReport()}</tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ReportControlSecertInfo;
