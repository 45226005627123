import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { mainApi } from '../../HttpClient';
import fileDownload from 'js-file-download';

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import $ from 'jquery';

import { getDateThaiShortFormat, getDateThaiStartAndEndFullMonth } from '../util/Date';
import { useSelector } from "react-redux";
// import { tr } from 'date-fns/locale';

const PreviewRptSecretDocDaily = (props) => {
    let history = useHistory();
    // const params = props.location.state || {};
    const params = useMemo(() => props.location.state || {}, [props]);
    const [reportData, setReportData] = useState({});
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        if (typeof params.createdDate === 'undefined' || params.createdDate === '') {
            history.push('/report-secret-doc-summary-by-month');
        }
    });

    const renderReportData = useCallback((data) => {
        console.log(data);
        if (data.length > 0) {
            $('#data-table').find('.dataTables_empty').remove();
            return data.map((item, index) => (
                <tr key={index}>
                    <td className="text-center">{showColumnReceiveNo(params.documentType) ? item.receiveNo : item.sentNo}</td>
                    <td className="text-left">
                        {item.registerNo}
                        {item.urgencyName !== '' && (<p className="mb-0 text-primary" style={{ fontSize: '11px' }}>{item.urgencyName}</p>)}
                    </td>
                    {/* <td className='text-left'>{item.urgencyLevel} / {item.secretLevel}</td> */}
                    <td className="text-center">{getDateThaiShortFormat(item.documentDate, true)}</td>
                    <td className="text-left">{item.owner}</td>
                    <td className="text-left">{item.receiver}</td>
                    <td className="text-left">{item.subject}</td>
                    <td className="text-left">{item.receiverId}</td>
                    <td className="text-left">
                        {item.command !== '' && (<p className="mb-0">{item.command}</p>)}
                        {item.action !== '' && (<p className="mb-0 text-success">{item.action}</p>)}
                    </td>
                    <td className="text-left">{item.remark}</td>
                </tr>
            ));
        } else {
            $('#data-table').find('.dataTables_empty').show();
        }
    }, [params]);

    const loadData = useCallback(async () => {
        setIsSearchLoading(true);
        const docNumStart = $('#docNumStart').val();
        const docNumEnd = $('#docNumEnd').val();
        let url = '';
        if (Object.keys(params).length > 0) {
            if (params.startDate !== '' && params.endDate !== '') {
                // รีพอทรายเดือน
                url = '/api/report-secret-doc-summary-by-month/report-monthly' +
                    '?documentType=' + params.documentType +
                    '&createdDate=' + params.createdDate +
                    '&startDate=' + params.startDate +
                    '&endDate=' + params.endDate +
                    '&docNumStart=' + docNumStart +
                    '&docNumEnd=' + docNumEnd +
                    '&documentById=' + documentState.documentBy.id;
            } else {
                // รีพอทรายวัน
                url = '/api/report-secret-doc-summary-by-month/report-daily' +
                    '?documentType=' + params.documentType +
                    '&createdDate=' + params.createdDate +
                    '&docNumStart=' + docNumStart +
                    '&docNumEnd=' + docNumEnd +
                    '&documentById=' + documentState.documentBy.id;
            }
            let res = await mainApi.get(url);

            setReportData(res.data);
            renderReportData(res.data);
        }
        setIsSearchLoading(false);
    }, [params, renderReportData, documentState.documentBy]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSearch = () => {
        loadData();
    };

    const handlePrint = () => {
        setIsPrintLoading(true);
        const docNumStart = $('#docNumStart').val();
        const docNumEnd = $('#docNumEnd').val();
        let docTitle = '';
        let url = '';

        if (params.documentType === 'HR') {
            docTitle = 'รายงานทะเบียนหนังสือรับ(ลับ)ประจำวัน'; 
        } else {
            docTitle = 'รายงานทะเบียนหนังสือส่ง(ลับ)ประจำวัน';
        }

        if (params.startDate !== '' && params.endDate !== '') {
            url = '/api/report-secret-doc-summary-by-month/report-monthly/pdf' +
                '?documentType=' + params.documentType +
                '&createdDate=' + params.createdDate +
                '&startDate=' + params.startDate +
                '&endDate=' + params.endDate +
                '&docNumStart=' + docNumStart +
                '&docNumEnd=' + docNumEnd +
                '&documentById=' + documentState.documentBy.id;
        } else {
            url =
                '/api/report-secret-doc-summary-by-month/report-daily/pdf/' +
                '?documentType=' + params.documentType +
                '&createdDate=' + params.createdDate +
                '&docNumStart=' + docNumStart +
                '&docNumEnd=' + docNumEnd +
                '&documentById=' + documentState.documentBy.id;
        }

        mainApi.get(url, { responseType: 'blob' }).then((res) => {
            fileDownload(res.data, docTitle + '.pdf');
            setIsPrintLoading(false);
        });
    };

    const getTextHeader = (docType) => {
        let textHeader = '';
        if (docType === 'HR') {
            textHeader += 'ทะเบียนหนังสือรับ (ลับ)';
        } else {
            textHeader += 'ทะเบียนหนังสือส่ง (ลับ)';
        }

        if (params.createdDate === 'รวม') {
            textHeader += ` ประจำวันที่ ${getDateThaiStartAndEndFullMonth(params.startDate, params.endDate, false)}`;
        } else {
            textHeader += ` ประจำวันที่ ${getDateThaiShortFormat(params.createdDate, false)}`;
        }

        return textHeader;
    };

    const showColumnReceiveNo = (docType) => {
        let display = true;
        if (docType !== 'HR') {
            display = false;
        }
        return display;
    };

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '100vh' }}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        รายงานประจำวัน
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./report" className="text-breadcrumb">
                                                รายงาน
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link
                                                to="./report-secret-doc-summary-by-month"
                                                className="text-breadcrumb"
                                            >
                                                รายงานสรุปการ รับ-ส่ง หนังสือ (ลับ)
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            รายงานประจำวัน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mt-2 text-normal">
                                    <div className="table-header-masexpert card-header text-dark text-center">
                                        {getTextHeader(params.documentType)}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-2 pt-2 text-center">
                                                <span className="text-color-form align-middle">
                                                    {params.documentType === 'HR' ? 'ค้นหาเลขรับ' : 'ค้นหาเลขส่ง'}
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-2 pt-1">
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    id="docNumStart"
                                                />
                                            </div>
                                            <div className="col-12 col-md-1 pt-2 text-center">
                                                <span className="text-color-form align-middle">-</span>
                                            </div>
                                            <div className="col-12 col-md-2 pt-1">
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    id="docNumEnd"
                                                />
                                            </div>
                                            <div className="col-sm-12 col-md-2 mt-2">
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    className="btn-no-border btn-color-add-small btn-block"
                                                    onClick={handleSearch}
                                                >
                                                    <i className="fas fa-search pr-2" />ค้นหา
                                                </Button>
                                            </div>
                                            <div className="col-sm-12 col-md-2 mt-2">
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    className="btn-no-border btn-color-submit btn-block"
                                                    disabled={isPrintLoading}
                                                    onClick={!isPrintLoading ? handlePrint : null}
                                                >
                                                    <i className="fas fa-file pr-2" />พิมพ์
                                                    {isPrintLoading && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="table-responsive mt-3">
                                            <Table
                                                striped
                                                bordered
                                                hover
                                                responsive
                                                className="text-center"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">{showColumnReceiveNo(params.documentType) ? 'เลขรับ' : 'เลขส่ง'}</th>
                                                        <th className="text-center" style={{ width: '150px' }}>ที่</th>
                                                        {/* <th className="text-center">ชั้นความเร็ว / ความลับ</th> */}
                                                        <th className="text-center">ลงวันที่</th>
                                                        <th className="text-center">จาก</th>
                                                        <th className="text-center">ถึง</th>
                                                        <th className="text-center">เรื่อง</th>
                                                        <th className="text-center">ผู้รับ</th>
                                                        <th className="text-center">การปฏิบัติ</th>
                                                        <th className="text-center">หมายเหตุ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isSearchLoading ? (<tr>
                                                            <td className="text-center" colSpan={8}>
                                                                <div
                                                                    className="spinner-border spinner-border-sm ml-2"
                                                                    role="status"
                                                                >
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                <label className="pt-2 pl-4">กรุณารอสักครู่</label>
                                                            </td>
                                                        </tr>
                                                        ) : renderReportData(reportData)
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default PreviewRptSecretDocDaily;
